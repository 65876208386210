import {
  Expression,
  ExpressionDataType,
  NotificationLevel,
  parseExpressionToFrontend
} from "enada-common";
import { Button, Dialog, Stack } from "@mui/material";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  postSimulateExpressionAsync,
  resetCalculatedSlice,
  selectCalculatedSimpleExpression,
  setIsCondition,
  setSimpleExpression
} from "../../../store/slices/calculatedFieldSlice";
import { selectFieldsValidForCalculation } from "../../../store/slices/fieldsSlice";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { EdisonTypography } from "enada-components";
import "./calculatedmodal.scss";

import ExpressionBuilder from "../../adminconfig/calculated/expressionbuilder/ExpressionBuilder";
import { parseExpressionToBackend } from "../../adminconfig/calculated/utils/parsing/parseCalculationToBackend";
import { useGetFieldsQuery } from "services/api";

export interface CalculatedModalProps {
  toggleModal: (value: boolean) => void;
  isOpen: boolean;
  expression?: Expression;
  onSave: (changeValue: Expression) => void;
}
const CalculatedModal: FC<CalculatedModalProps> = ({ isOpen, toggleModal, expression, onSave }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const simpleExpression = useAppSelector(selectCalculatedSimpleExpression);

  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectFieldsValidForCalculation(result)
    })
  });

  const parsed = useMemo(() => {
    if (expression) return parseExpressionToFrontend(expression);
  }, [expression]);

  useEffect(() => {
    dispatch(setIsCondition(false));
    if (parsed) {
      dispatch(setSimpleExpression(parsed));
    } else {
      dispatch(setSimpleExpression([]));
    }
  }, [dispatch, parsed]);

  const onClose = () => {
    dispatch(resetCalculatedSlice(parsed));
    toggleModal(false);
  };

  return (
    <Dialog open={isOpen} maxWidth="xl" fullWidth={true} onClose={onClose}>
      <Stack
        className="calculated-modal-root"
        spacing={2}
        data-testid={"workflow-expressionbuilder-modal"}
      >
        <Stack direction="row" className="top-bar">
          <EdisonTypography variant="h4" title={t("expressionBuilder")} />
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={onClose}>
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              data-testid={"workflow-expressionbuilder-save-btn"}
              onClick={async () => {
                try {
                  const parsed = parseExpressionToBackend(
                    simpleExpression,
                    fields,
                    ExpressionDataType.Bool
                  );
                  const result = await dispatch(postSimulateExpressionAsync(parsed));
                  if (result.meta.requestStatus === "rejected") {
                    dispatch(
                      setCurrentNotification({
                        title: "Invalid Expression",
                        message: "",
                        level: NotificationLevel.Error
                      })
                    );
                    return;
                  }
                  onSave(parsed);
                  onClose();
                } catch (e) {
                  dispatch(
                    setCurrentNotification({
                      title: "Invalid Expression",
                      message: e as string,
                      level: NotificationLevel.Error
                    })
                  );
                }
              }}
            >
              {t("save")}
            </Button>
          </Stack>
        </Stack>
        <ExpressionBuilder allowConditions={false} />
      </Stack>
    </Dialog>
  );
};

export default CalculatedModal;
