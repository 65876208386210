import { apiConfig } from "../../config/authConfig";
import { baseAPI } from "../baseAPI";

export const recordsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getDistinctValues: builder.query<string[], { propertyName: string }>({
      query: ({ propertyName }) =>
        `${apiConfig.recordsODataEndpoint}${decodeURIComponent(
          `?$apply=groupby((${propertyName}))`
        )}`,
      transformResponse: (response: { data: string[] }) => response.data
    })
  })
});
