import { Checkbox, FormControlLabel, Stack, Tooltip } from "@mui/material";
import React, { FC } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useToggleAutoHeight } from "../../../tableutils/useToggleAutoHeight";
export interface PeriodicToolbarSettingsModalProps {
  grid: any;
  label: string;
  columns: any[];
  t: (value: string) => string;
}
const PeriodicToolbarSettings: FC<PeriodicToolbarSettingsModalProps> = ({
  grid,
  columns,
  label,
  t
}) => {
  const { toggleAutoHeight, autoHeight } = useToggleAutoHeight(
    columns,
    grid.current?.instance,
    label
  );
  return (
    <Stack padding={"1em"}>
      <Stack direction={"row"} className="align-center">
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(autoHeight)}
              onChange={e => {
                toggleAutoHeight(!autoHeight);
              }}
            />
          }
          label={t("dataWrapping")}
        />
        <Tooltip title={t("dataWrappingRowMessage")} arrow placement="right">
          <InfoOutlinedIcon />
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default PeriodicToolbarSettings;
export { PeriodicToolbarSettings };
