import React, { FC } from "react";
import { ColumnTable, RecordRole, Table, WorkflowStageViewFieldState } from "enada-common";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectAllCalendars, selectCalendarStatus } from "../../../../store/slices/calendarsSlice";
import {
  selectCurrentVersion,
  selectEditor,
  selectRecord,
  selectRecordFields,
  selectRecordPermissions,
  selectRecordReadOnly,
  selectRecordVersionPeriodicGrandTotals,
  selectRecordVersionTableConfigurations,
  selectWorkflowStage
} from "../../../../store/slices/recordSlice";
import {
  selectRecordTableBackendRowValues,
  selectRecordTablePeriodicGrandTotals,
  selectRecordTableStatus,
  selectRecordTables,
  selectTableConfigs,
  setTableOperations,
  selectRecordRowsToUpdate,
  removeRowToUpdate,
  selectRowsLoadedFromVersion
} from "../../../../store/slices/recordTableSlice";
import { selectMyResources, selectResourcesStatus } from "../../../../store/slices/resourcesSlice";
import { Loading } from "enada-components";
import TableMapper from "../../../../utils/mappers/TableMapper";
import { RowOperationType } from "../../../../utils/parsing/parseTableChangeToBackend";
import { selectAiEnabled } from "../../../../store/slices/tenantSlice";
import { useGetMyRolesQuery, useGetUserQuery } from "services/api";

export interface ProjectTableProps {
  columnTable: ColumnTable;
}
const ProjectTable: FC<ProjectTableProps> = ({ columnTable }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const { data: user } = useGetUserQuery();
  const { data: userRoles = [] } = useGetMyRolesQuery();

  const projectEditor = useAppSelector(selectEditor);
  const keepReadOnly = useAppSelector(selectRecordReadOnly);
  const fields = useAppSelector(selectRecordFields);
  const fullProject = useAppSelector(selectRecord);
  const rowsToUpdate = useAppSelector(selectRecordRowsToUpdate);
  const projectTableRows = useAppSelector(selectRecordTableBackendRowValues);
  const periodicGrandTotals = useAppSelector(selectRecordTablePeriodicGrandTotals);
  const projectTableRowsStatus = useAppSelector(selectRecordTableStatus);
  const tables = useAppSelector(selectRecordTables);
  const workflowStage = useAppSelector(selectWorkflowStage);
  const resources: any[] = useAppSelector(selectMyResources);
  const resourcesStatus = useAppSelector(selectResourcesStatus);
  const aiEnabled = useAppSelector(selectAiEnabled);
  const rowsLoadedFromVersion = useAppSelector(selectRowsLoadedFromVersion);
  const currentVersion = useAppSelector(selectCurrentVersion);
  const recordVersionTableConfig = useAppSelector(selectRecordVersionTableConfigurations);
  const recordVersionPeriodicGrandTotals = useAppSelector(selectRecordVersionPeriodicGrandTotals);

  const calendars: any[] = useAppSelector(selectAllCalendars);
  const calendarsStatus = useAppSelector(selectCalendarStatus);

  const tableConfigs = useAppSelector(selectTableConfigs);
  const recordPermissions = useAppSelector(selectRecordPermissions);

  const stageView =
    workflowStage?.views && workflowStage?.views?.length !== 0
      ? (workflowStage?.views)[0] // TODO : review this when we can add multiple views to the a workflow
      : null;

  const isEditor = user?.id === projectEditor?.oid;

  let fullTable: Table | undefined = undefined;
  if (tables) {
    fullTable = tables.find(table => table.id === columnTable.tableId);
  }

  const isReadOnly = () => {
    if (keepReadOnly) return true;
    if (isEditor) {
      const isAdmin = userRoles.some(role =>
        ["Tenant Admin", "Instance Admin"].includes(role?.value ?? "")
      );
      const isProcessManager = recordPermissions.some(
        permission => permission.entityId === user?.id && permission.role === RecordRole.Manager
      );
      if (workflowStage?.allowReadOnlyOverride && (isProcessManager || isAdmin)) return false;

      return Boolean(
        stageView?.viewTables &&
          stageView.viewTables.some(
            viewTable =>
              viewTable.tableId === columnTable.tableId &&
              viewTable.state === WorkflowStageViewFieldState.ReadOnly
          )
      );
    }
    return true;
  };

  if (!fullTable) {
    return <></>;
  }

  return (
    <div>
      {fullTable &&
      tableConfigs &&
      resourcesStatus === "idle" &&
      calendarsStatus === "idle" &&
      projectTableRowsStatus === "idle" ? (
        <TableMapper
          table={fullTable}
          fields={fields}
          t={t}
          project={fullProject ?? undefined}
          rowsToUpdate={rowsToUpdate.filter(row => row.tableId === columnTable.tableId)}
          removeUpdatedRowAction={(uniqueId: string) => dispatch(removeRowToUpdate(uniqueId))}
          rowsInTable={projectTableRows.filter(row => row.tableId === columnTable.tableId)}
          periodicGrandTotals={
            !currentVersion
              ? periodicGrandTotals.find(totals => totals.tableId === fullTable?.id)?.totals
              : recordVersionPeriodicGrandTotals?.find(totals => totals.tableId === fullTable?.id)
                  ?.totals
          }
          readOnly={isReadOnly()}
          resources={resources}
          calendars={calendars}
          tableConfig={
            !currentVersion
              ? tableConfigs.find(t => t.tableId === fullTable?.id)
              : recordVersionTableConfig?.find(t => t.tableId === fullTable?.id)
          }
          onChange={(tableOperations: RowOperationType[]) => {
            dispatch(setTableOperations({ tableOperations }));
          }}
          deleteAction={(value: any) => {
            console.log("delete", value);
          }}
          updateAction={(value: any) => {
            // dispatch(updateProjectRowValues(value));
          }}
          required={stageView?.viewTables?.some(
            viewTable =>
              viewTable.state === WorkflowStageViewFieldState.Required &&
              viewTable.tableId === columnTable.tableId
          )}
          hideEdi={(!aiEnabled && !currentVersion) || isReadOnly()}
          refreshTables={rowsLoadedFromVersion}
        />
      ) : (
        <Loading size={70} />
      )}
    </div>
  );
};
export default ProjectTable;
