import {
  BaseWorkItem,
  BaseRecord,
  WorkItemApproval,
  WorkflowReviewState,
  WorkflowTaskState,
  WorkItemTask,
  WorkItem
} from "enada-common";
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  getFavourites,
  getMyWorkApprovals,
  getMyWorkDashboardItems,
  getMyWorkItem,
  getMyWorkSavedItem,
  getMyWorkSavedTask,
  getMyWorkTask,
  getMyWorkTasks,
  getOwnedItems
} from "../../services/APIService";
import { RootState } from "../store";

export interface personalState {
  approvals: WorkItemApproval[];
  dashboardItems: BaseWorkItem[];
  favourites: BaseRecord[];
  tasks: WorkItemTask[];
  ownedItems: WorkItem[];
}

const initialState: personalState = {
  approvals: [],
  dashboardItems: [],
  favourites: [],
  tasks: [],
  ownedItems: []
};

export const getFavouritesAsync = createAsyncThunk(
  "personal/favourites",
  async (querystring: string) => {
    const response = await getFavourites(querystring);
    return response.value;
  }
);

export const getApprovalsAsync = createAsyncThunk(
  "personal/getApprovals",
  async (_, { rejectWithValue }) => {
    const response: any = await getMyWorkApprovals();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const getDashboardItemsAsync = createAsyncThunk(
  "personal/getDashboardItems",
  async (_, { rejectWithValue }) => {
    const response: any = await getMyWorkDashboardItems();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const getPersonalTasksAsync = createAsyncThunk(
  "personal/getPersonalTasks",
  async (_, { rejectWithValue }) => {
    const response: any = await getMyWorkTasks();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);
export const getOwnedItemsAsync = createAsyncThunk(
  "personal/getOwnedItemsAsync",
  async (_, { rejectWithValue }) => {
    const response: any = await getOwnedItems();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const getMyWorkItemAsync = createAsyncThunk(
  "personal/getPersonalItem",
  async (itemID: number, { rejectWithValue }) => {
    const response = await getMyWorkItem(itemID);
    if (!response.status.toString().startsWith("2")) return rejectWithValue(response.data);
    return response.data;
  }
);

export const getMyWorkSavedItemAsync = createAsyncThunk(
  "personal/getPersonalSavedItem",
  async (itemID: number, { rejectWithValue }) => {
    const response = await getMyWorkSavedItem(itemID);

    if (!response.status.toString().startsWith("2")) return rejectWithValue(response.data);
    return response?.data?.pendingRecordTableRowValue; // just return the row fields
  }
);

export const getMyWorkTaskAsync = createAsyncThunk(
  "personal/getPersonalTask",
  async (itemID: number, { rejectWithValue }) => {
    const response = await getMyWorkTask(itemID);
    if (!response.status.toString().startsWith("2")) return rejectWithValue(response.data);
    return response.data;
  }
);

export const getMyWorkSavedTaskAsync = createAsyncThunk(
  "personal/getPersonalSavedTask",
  async (itemID: number, { rejectWithValue }) => {
    const response = await getMyWorkSavedTask(itemID);

    if (!response.status.toString().startsWith("2")) return rejectWithValue(response.data);
    return response?.data?.pendingRecordTableRowValue; // just return the row fields
  }
);

export const personalSlice = createSlice({
  name: "personal",
  initialState,
  reducers: {
    updateApprovalTask: (
      state,
      action: PayloadAction<{
        recordId?: number;
        taskId?: number;
        newState?: WorkflowTaskState;
      }>
    ) => {
      const { recordId, taskId, newState } = action.payload;
      state.approvals = state.approvals.map(approval =>
        approval.recordId === recordId
          ? {
              ...approval,
              reviewTasks: approval.reviewTasks?.map(task =>
                task.taskId === taskId ? { ...task, taskState: newState } : task
              )
            }
          : approval
      );
    },
    updateApprovalReviewState: (
      state,
      action: PayloadAction<{
        recordId?: number;
        newState: WorkflowReviewState;
        comments: string;
      }>
    ) => {
      const { recordId, newState, comments } = action.payload;
      state.approvals = state.approvals.map(approval =>
        approval.recordId === recordId ? { ...approval, reviewState: newState, comments } : approval
      );
    },
    removeApproval: (state, action: PayloadAction<WorkItemApproval>) => {
      if (action.payload === undefined) return;
      const { recordId, itemType, stageId } = action.payload;
      state.approvals = state.approvals.filter(
        approval =>
          approval.itemType === itemType &&
          approval.recordId === recordId &&
          approval.stageId === stageId
      );
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getApprovalsAsync.fulfilled, (state, action) => {
        state.approvals = action.payload;
      })
      .addCase(getDashboardItemsAsync.fulfilled, (state, action) => {
        state.dashboardItems = action.payload;
      })
      .addCase(getPersonalTasksAsync.fulfilled, (state, action) => {
        state.tasks = action.payload;
      })
      .addCase(getOwnedItemsAsync.fulfilled, (state, action) => {
        state.ownedItems = action.payload;
      });
  }
});

export const { updateApprovalTask, updateApprovalReviewState, removeApproval } =
  personalSlice.actions;

const inputSelectPersonal = (state: RootState) => state.personal;

export const selectPersonalApprovals = createSelector(
  [inputSelectPersonal],
  personal => personal.approvals
);

export const selectPersonalDashboardItems = createSelector(
  [inputSelectPersonal],
  personal => personal.dashboardItems
);

export const selectPersonalTaskItems = createSelector(
  [inputSelectPersonal],
  personal => personal.tasks
);

export const selectPersonalOwnedItems = createSelector(
  [inputSelectPersonal],
  personal => personal.ownedItems
);

export default personalSlice.reducer;
