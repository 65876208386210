import { ProjectModel, ProjectModelConfig } from "@bryntum/gantt-thin";
import { ProjectModel as TaskBoardProjectModel } from "@bryntum/taskboard-thin";
import { BryntumGantt } from "@bryntum/gantt-react-thin";
import { Choice, Field, StandardTableProps } from "enada-common";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import React, { FC, useEffect, useRef, useState } from "react";
import EdisonTypography from "../../../edison/typography/EdisonTypography";
import "../../../theme/bryntumcustomtheme.scss";
import StandardBoard, { StandBoardCardItem } from "../board/UserTableBoard";
import { StandardReadOnlyContext } from "./StandardContext";
import StandardTableToolbar from "./toolbar/StandardTableToolbar";
import "./userlisttable.scss";
import "@bryntum/core-thin/core.material.css";
import "@bryntum/grid-thin/grid.material.css";
import "@bryntum/gantt-thin/gantt.material.css";
import { gridConfig } from "./utils/GridConfig";
import generateStandardTableColumns from "./utils/generateStandardTableColumns";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useToggleAutoHeight } from "../tableutils/useToggleAutoHeight";
import { dynamicColumns } from "../tableutils/isColumnDynamic";
import { getClassToSearchFor } from "../tableutils/generateBaseBryntumColumn";
import { ColumnStore } from "@bryntum/grid-thin";
import { HORIZONTAL_SCROLL_HEIGHT, ROW_HEIGHT, TOOLBAR_HEIGHT } from "../tableutils/taskTableUtils";

export interface NewListTableProps extends StandardTableProps {
  showBoardView?: boolean;
  boardColumns?: Choice[];
  columnField: string;
  toolbarModule: React.ReactNode;
  cardItems?: StandBoardCardItem[];
  hiddenColumns: Field[];
  onEdiClick?: () => void;
  required?: boolean;
  rowsToUpdate: any[];
  t: (value: string) => string;
  refreshRequired?: boolean;
  hideEdi?: boolean;
}

const UserListTable: FC<NewListTableProps> = ({
  data,
  columns,
  onDataChanged,
  onEdiClick,
  hideExportBtn = false,
  hideDeleteBtn = false,
  hideAddBtn = false,
  hideSearch = false,
  label,
  readOnly,
  hideEdi,
  showBoardView,
  boardColumns,
  columnField,
  toolbarModule,
  cardItems,
  hiddenColumns,
  required,
  description,
  rowsToUpdate,
  refreshRequired,
  t
}) => {
  const ganttRef = useRef<any>();
  const dynamicHeightLabel = `tableAutoHeight-${label.replace(" ", "-")}`;
  const columnsRef = useRef(generateStandardTableColumns(columns, dynamicHeightLabel));

  const project = useRef<Partial<ProjectModelConfig>>(
    new ProjectModel({ onDataReady: () => setLoaded(true) }) as Partial<ProjectModelConfig>
  );

  const [fullScreen, setFullScreen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [hasSelectedData, setHasSelectedData] = useState<boolean>(false);

  const { toggleAutoHeight, autoHeight } = useToggleAutoHeight(
    columns,
    ganttRef.current?.instance,
    label
  );

  const columnOrder = columns.reduce((acc: string, curr) => {
    return `${acc}-${curr.id}`;
  }, "");

  useEffect(() => {
    (project.current as ProjectModel).taskStore.data = data;
  }, []);

  useEffect(() => {
    if (refreshRequired) {
      const store = (project.current as ProjectModel).taskStore;
      if (store.records.length) {
        store.removeAll();
      }
      loadData();
    }
  }, [refreshRequired]);

  const loadData = () => {
    (project.current as ProjectModel).taskStore.data = data;
  };

  useEffect(() => {
    ganttRef.current.instance.selectionMode.checkbox = false;
    ganttRef.current.instance.columns.removeAll(true);
    ganttRef.current.instance.columns.add(
      generateStandardTableColumns(columns, dynamicHeightLabel),
      true
    );
    ganttRef.current.instance.renderContents();

    ganttRef.current.instance.selectionMode.checkbox = true;
  }, [columnOrder]);

  const onFocusOut = (e: any) => {
    if (!e.backwards) return;
    const selected = ganttRef.current?.instance.selectedCell as any;
    if (!selected) return;
    ganttRef.current?.instance.deselectCell(selected);
  };
  useEffect(() => {
    // Use to control the columns visibility from the views
    const columnStore = ganttRef.current.instance.columns as ColumnStore;
    columns.forEach(column => {
      const bryntumColumn = columnStore.get(column.name);
      bryntumColumn.hidden = hiddenColumns?.some(hiddenColumn => hiddenColumn.id === column.id);
    });
  }, [hiddenColumns]);

  useEffect(() => {
    //Live update values in row
    rowsToUpdate.forEach(row => {
      const { rowId, ...rest } = row;
      const task = (project.current as ProjectModel).taskStore.getById(rowId);
      task.set({ ...rest });
    });
  }, [rowsToUpdate]);

  useEffect(() => {
    // Once the gantt is loaded, synchronize the tables autoheight with the one in local storage
    if (!loaded) return;
    if (!autoHeight) return;
    toggleAutoHeight(autoHeight);
  }, [loaded]);

  return (
    <div className={`standard-table-root ${fullScreen && "fullscreen"} `}>
      <div className={`${fullScreen && "sticky"}`}>
        <StandardTableToolbar
          grid={ganttRef}
          columns={columns}
          hideExportBtn={hideExportBtn}
          hideDeleteBtn={hideDeleteBtn}
          hideAddBtn={hideAddBtn}
          hideSearch={hideSearch}
          toolbarModule={toolbarModule}
          onEdiClick={onEdiClick}
          readOnly={readOnly}
          hideEdi={hideEdi}
          hasSelectedData={hasSelectedData}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          t={t}
          label={label}
          titleModule={
            <Stack direction="row" spacing={1}>
              <EdisonTypography
                title={label}
                sx={{
                  color: required && data.length === 0 ? "red" : undefined
                }}
                variant="data"
              />

              {description && (
                <Tooltip title={description} arrow placement="right">
                  <InfoOutlinedIcon fontSize="small" className="table-info-icon" />
                </Tooltip>
              )}
              {readOnly && <LockOutlinedIcon fontSize="small" className="table-readonly-icon" />}
            </Stack>
          }
        />
      </div>
      <Divider />
      <div className={`${showBoardView ? "standard-table-visible" : "standard-table-hidden"}`}>
        {boardColumns?.length && (
          <StandardBoard
            project={project.current as TaskBoardProjectModel}
            boardColumns={boardColumns}
            tableColumns={columns}
            columnField={columnField}
            cardItems={cardItems}
          />
        )}
      </div>
      <div
        className={`standard-table-container enada-bryntum-grid ${
          showBoardView ? "standard-table-hidden" : "standard-table-visible"
        }`}
      >
        <StandardReadOnlyContext.Provider value={Boolean(readOnly)}>
          <BryntumGantt
            {...gridConfig}
            columns={columnsRef.current}
            project={project.current}
            ref={ganttRef}
            onFocusOut={onFocusOut}
            onDataChange={onDataChanged}
            readOnly={readOnly}
            maxHeight={"calc(100vh - 65px)"}
            height={`${
              (data?.length ? data?.length * ROW_HEIGHT : 0) +
              TOOLBAR_HEIGHT +
              HORIZONTAL_SCROLL_HEIGHT
            }px`}
            rowHeight={ROW_HEIGHT}
            onSelectionChange={e => setHasSelectedData(e.selection.length > 0)}
          />
        </StandardReadOnlyContext.Provider>
      </div>
    </div>
  );
};

export default UserListTable;
export { UserListTable };
