import { NotificationLevel, ResourceField } from "enada-common";
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getResourceFields, postResourceFields } from "../../services/APIService";
import { RootState } from "../../store/store";
import { setCurrentNotification } from "./notificationSlice";

export interface resourceFieldState {
  resourceFields: ResourceField[];
  status: "idle" | "loading" | "failed";
}
const initialState: resourceFieldState = {
  resourceFields: [],
  status: "idle"
};

export const getResourceFieldsAsync = createAsyncThunk("personas/getResourceFields", async () => {
  const response = await getResourceFields();
  return response.data;
});

export const postResourceFieldsAsync = createAsyncThunk(
  "personas/postResourceFields",
  async (data: ResourceField[], { rejectWithValue, dispatch }) => {
    const response = await postResourceFields(data);
    dispatch(
      setCurrentNotification({
        title: "resourceFieldsUpdated",
        message: "",
        level: NotificationLevel.Success
      })
    );
    if (!(response.status as number).toString().startsWith("2")) {
      dispatch(
        setCurrentNotification({
          title: "resourceFieldsUpdateError",
          message: `\n ${response.detail}`,
          level: NotificationLevel.Error
        })
      );
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);

const resourceFieldsSlice = createSlice({
  name: "resourceFields",
  initialState,
  reducers: {
    updateResourceFields: (state, action: PayloadAction<ResourceField[]>) => {
      state.resourceFields = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(
        getResourceFieldsAsync.fulfilled,
        (state, action: PayloadAction<ResourceField[]>) => {
          if (!action.payload) return;
          state.resourceFields = action.payload;
        }
      )
      .addCase(postResourceFieldsAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(postResourceFieldsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(postResourceFieldsAsync.fulfilled, (state, action) => {
        state.resourceFields = action.payload;
      });
  }
});

export const { updateResourceFields } = resourceFieldsSlice.actions;

const inputSelectResourceFields = (state: RootState) => state.resourceFields;

export const selectResourceFields = createSelector(
  [inputSelectResourceFields],
  (resource): ResourceField[] => resource.resourceFields
);

export default resourceFieldsSlice.reducer;
