import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getReportAsync,
  selectResourcesStatus,
  selectFilteredResourceReport,
  clearFilteredReport,
  selectResourcesRollUp,
  getResourcesAsync
} from "../../../store/slices/resourcesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  EdisonResourceFilter,
  EdisonResourceReport,
  EdisonTypography,
  Loading
} from "enada-components";
import { Person, PersonCard } from "@microsoft/mgt-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { Calendar, FilterOperation, FilterPropertyType, RecordType } from "enada-common";
import { Paper, Popover, Stack } from "@mui/material";
import buildQuery from "odata-query";
import "./resourcereport.scss";
import { compareFilters, extractOdata } from "../../../utils/filterUtils";
import { useGetCalendarsQuery } from "services/api";
import { selectInData } from "utils/rtkQuery";

const selectResourceCalendars = selectInData<Calendar[]>(data => {
  return data?.map(calendar => ({
    id: calendar.id,
    name: calendar.name,
    displayName: calendar.displayName,
    intervals: calendar.intervals,
    unspecifiedTimeIsWorking: false
  }));
});

const ResourceReport = () => {
  const { t } = useTranslation(["common"]);

  const { calendars = [] } = useGetCalendarsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      calendars: selectResourceCalendars(result)
    })
  });

  const [activeFilters, setActiveFilters] = useState<FilterOperation[]>([]);
  const [odataQuery, setOdataQuery] = useState("");
  const [odataTaskQuery, setOdataTaskQuery] = useState("");
  const [viewStartDate, setViewStartDate] = useState<Date | null>(null);
  const [viewEndDate, setViewEndDate] = useState<Date | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);

  // TODO: Replace all instances of this code by a component
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [clickedPersona, setClickedPersona] = useState<any>(null);
  const open = Boolean(anchorEl);

  // TODO: Replace with my resources
  const resources: any[] = useAppSelector(selectResourcesRollUp);
  const filteredResourceReport: any = useAppSelector(selectFilteredResourceReport);
  const resourcesStatus: string = useAppSelector(selectResourcesStatus);
  const dispatch = useAppDispatch();

  const GetPersonComponent = (record: any) => {
    const isRecord =
      record.originalData.recordType === RecordType.Projects ||
      record.originalData.recordType === RecordType.BusinessCase ||
      record.originalData.recordType === RecordType.Programs;

    if (isRecord) return <EdisonTypography variant="data" title={record.originalData.name} />;

    if (record.isResourceModel || record.isResourceUtilizationModel) {
      let person;
      if (record.type === "AAD") {
        person = null;
      } else {
        person = {
          displayName: record.name
        };
      }

      return (
        <Person
          className="avatar-large"
          avatarType="photo"
          userId={person ? undefined : record.userId}
          personDetails={person ?? undefined}
          fetchImage={person ? undefined : true}
          showPresence={person ? undefined : true}
          view={"oneline"}
          line1Property="displayName"
          personCardInteraction={"none"}
          onClick={e => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
            setClickedPersona(record.userId);
            const temporaryFix = () => {
              // TODO / WARNING: The below should be changed once MUI fix the issue where popovers don't update the position if the height is based on async data
              window.dispatchEvent(new CustomEvent("resize"));
            };
            setTimeout(temporaryFix, 0);
          }}
          avatarSize="large"
        ></Person>
      );
    }
    return record.name;
  };

  const cols = [
    {
      type: "tree",
      text: "Name",
      field: "name",
      width: 160,
      renderer: ({ record, value }: any) => {
        if (record.isResourceModel) {
          return GetPersonComponent(record);
        }
        return value;
      }
    },
    { text: "Calendar", field: "calendarName", width: 120 },
    { text: "Role", field: "role", width: 120 }
  ];

  const columns = useRef(cols);

  const fetchData = useCallback(async () => {
    await dispatch(getReportAsync({ odataQuery, odataTaskQuery }));
  }, [dispatch, odataQuery, odataTaskQuery]);

  useEffect(() => {
    dispatch(getResourcesAsync());

    return () => {
      dispatch(clearFilteredReport());
    };
  }, [dispatch]);

  useEffect(() => {
    if (initialLoad) {
      return;
    }
    fetchData();
  }, [fetchData, initialLoad, odataQuery, odataTaskQuery]);

  const extendedCalendars = useMemo(() => {
    const fullCalendar = {
      id: "e365-24-7-working",
      name: "e365-24-7-working",
      unspecifiedTimeIsWorking: true
    };
    if (calendars) {
      return [...calendars, ...[fullCalendar]];
    }
    return [fullCalendar];
  }, [calendars]);

  const extractTaskOdataQuery = useCallback(
    (filters: FilterOperation[]) => {
      let resourceIds = [];
      const resourceFilter = filters.filter(f => f.property === "id");
      if (resourceFilter && resourceFilter.length > 0) {
        resourceIds = resourceFilter[0].value?.map((v: FilterOperation) => v.value) ?? [];
      }

      const recordTableRowAssignments = resourceIds.length
        ? {
            any: { resource: { in: resourceIds } }
          }
        : null;

      const uniqueRecordTypeFilter = Array.from(
        new Set(
          activeFilters
            .filter(f => f.property === "recordType")
            .flatMap(recordType =>
              recordType.value.map(({ value }: { value: RecordType }) => value)
            )
        )
      ).map(value => ({ RecordType: value }));

      const getDateValue = (date: Date) => {
        date = new Date(date);
        return `${date.getUTCFullYear()}-${
          date.getUTCMonth() + 1 // Its index base needs to add 1
        }-${date.getUTCDate()}`;
      };

      const expand = ["RecordTableRowAssignments", "RecordOwners"];

      if (viewStartDate && viewEndDate) {
        const or = [
          {
            and: {
              startDate: {
                le: {
                  type: "raw",
                  value: getDateValue(viewEndDate)
                }
              },
              endDate: {
                ge: {
                  type: "raw",
                  value: getDateValue(viewStartDate)
                }
              }
            }
          }
        ];

        const anded = {
          and: [
            recordTableRowAssignments ? { recordTableRowAssignments } : {},
            ...uniqueRecordTypeFilter,
            { or }
          ]
        };

        return buildQuery({ filter: anded, expand });
      }

      return buildQuery({
        filter: { recordTableRowAssignments, ...uniqueRecordTypeFilter },
        expand
      });
    },
    [activeFilters, viewStartDate, viewEndDate]
  );

  useEffect(() => {
    setOdataTaskQuery(extractTaskOdataQuery(activeFilters));
    const filteredResources = activeFilters.filter(filter => filter.property === "id");

    if (filteredResources.length > 0)
      setOdataQuery(
        buildQuery({
          ...extractOdata({
            skip: 0,
            filters: filteredResources ?? []
          }),
          top: 100
        })
      );
  }, [activeFilters, viewStartDate, viewEndDate, extractTaskOdataQuery]);

  const getFilters = (resources: any[]): FilterOperation[] => {
    const filters: FilterOperation[] = [];

    if (resources) {
      const distinctNames: any[] = [];
      resources.forEach((r: any) => {
        if (distinctNames.findIndex((n: any) => n.value === r.id) < 0) {
          distinctNames.push({
            displayName: r.displayName ?? "",
            value: r.id,
            extraProps: { type: r.type, userId: r.userId }
          });
        }
      });
      filters.push({
        property: "id",
        displayName: "Name",
        propertyType: FilterPropertyType.ResourcePicker,
        options: distinctNames
      });
    }
    // filters.push({
    //   displayName: t("recordType"),
    //   property: "recordType",
    //   propertyType: FilterPropertyType.MultiChoice,
    //   options: [
    //     { displayName: t("projects"), value: RecordType.Projects },
    //     { displayName: t("businessCases"), value: RecordType.BusinessCase },
    //     { displayName: t("programs"), value: RecordType.Programs }
    //   ]
    // });
    return filters;
  };

  const filters = useMemo(() => getFilters(resources), [resources]);

  const onFiltersApply = async (f: FilterOperation[], start: Date, end: Date) => {
    if (
      f.length === 0 ||
      (compareFilters(f, activeFilters) && start === viewStartDate && end === viewEndDate)
    ) {
      return;
    }

    if (initialLoad) {
      setInitialLoad(false);
    }

    setViewStartDate(start);
    setViewEndDate(end);
    setActiveFilters(f);
  };

  if (resourcesStatus === "loading") {
    return <Loading size={150} sx={{ marginTop: "30px" }} />;
  }

  return (
    <Paper className="report-wrapper">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <EdisonResourceFilter
          filterOperations={filters}
          activeFilterOperations={activeFilters}
          viewStartDate={viewStartDate || undefined}
          viewEndDate={viewEndDate || undefined}
          onClose={onFiltersApply}
          addFilterDisplayName={t("addFilter")}
          editFiltersDisplayName={t("editFilters")}
          applyDisplayName={t("generateReport")}
          dateRangeDisplayName={t("dateRange")}
          viewStartDisplayName={t("viewStart")}
          viewEndDisplayName={t("viewEnd")}
          filterInfoDisplayName={t("filtersApplied")}
          filterInfoEmptyMessage={t("FiltersEmptyMessage")}
          propertyDisplayName={""}
          operatorDisplayName={""}
          valueDisplayName={""}
        />
        {!filteredResourceReport && (
          <Stack
            className="no-data-container"
            direction="row"
            spacing={2}
            justifyContent="space-around"
          >
            <EdisonTypography variant="data" title={t("resourceReportNoData")} />
            <EdisonTypography variant="data" title={t("resourceReportNoData")} />
          </Stack>
        )}

        {filteredResourceReport && (
          <EdisonResourceReport
            events={filteredResourceReport.tasks}
            resources={filteredResourceReport.resources}
            assignments={filteredResourceReport.assignments}
            recordOwners={filteredResourceReport.recordOwners}
            columns={columns.current}
            calendar="e365-24-7-working"
            calendars={extendedCalendars}
            startDate={filteredResourceReport.startDate}
            endDate={filteredResourceReport.endDate}
            readOnly={true}
            viewDataDisplayName={t("viewData")}
            histogramDisplayName={t("histogram")}
            utilizationDisplayName={t("utilization")}
            dialogTitleDisplayName={t("information")}
            recordDisplayName={t("recordName")}
            taskDisplayName={t("taskName")}
            durationDisplayName={t("duration")}
            fromDisplayName={t("from")}
            toDisplayName={t("to")}
            GetPersonComponent={GetPersonComponent}
          />
        )}
      </LocalizationProvider>
      {anchorEl && clickedPersona && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClick={e => e.stopPropagation()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <PersonCard userId={clickedPersona} showPresence fetchImage isExpanded={true} />
        </Popover>
      )}
    </Paper>
  );
};

export default ResourceReport;
