import {
  ApprovalState,
  PersonalPageState,
  RecordTableRow,
  Table,
  WorkItem,
  WorkItemTask,
  WorkItemType
} from "enada-common";
import { useCallback, useMemo, useState } from "react";

import {
  getMyWorkItemAsync,
  getMyWorkSavedItemAsync,
  getMyWorkTaskAsync,
  getMyWorkSavedTaskAsync
} from "../../store/slices/personalSlice";
import { getIndividualTable } from "../../services/APIService";
import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";
import { useAppDispatch } from "../../store/hooks";

export const useGetMyWorkRows = (item: WorkItem | WorkItemTask) => {
  const dispatch = useAppDispatch();
  const [foundRecordTableRows, setFoundRecordTableRows] = useState<RecordTableRow>();
  const [table, setTable] = useState<Table | undefined>();

  const isSaved = useMemo(
    () =>
      item.approvalState === ApprovalState.Saved ||
      item.approvalState === ApprovalState.Submitted ||
      item.approvalState === ApprovalState.Rejected,
    [item.approvalState]
  );

  const apiToUse = useMemo(() => {
    if (!item.itemId) return;

    if (item.itemType !== WorkItemType.Task) {
      // use these get functions for anything other than a task i.e. ItemList
      return isSaved ? getMyWorkSavedItemAsync(item.itemId) : getMyWorkItemAsync(item.itemId);
    } else {
      return isSaved ? getMyWorkSavedTaskAsync(item.itemId) : getMyWorkTaskAsync(item.itemId);
    }
  }, [isSaved, item.itemId, item.itemType]);

  const onGetItemFields = useCallback(async () => {
    if (!item.itemId || !apiToUse) return;
    const response = await dispatch(apiToUse).unwrap();

    if (response) {
      // TODO: remove this endpoint when my work/personal endpoints are moved over to RTK
      const table = ((await getIndividualTable(response?.tableId as number)) as { data: Table })
        .data;

      const filteredRows = response?.tableRowFieldValues?.filter(
        fieldValue =>
          !table?.fields?.some(
            field =>
              field.fieldId === fieldValue.fieldId &&
              field.fieldId !== SystemFieldType.UniqueId && // always need to include the uniqueId as its used to match the row on the backend
              [PersonalPageState.Hidden, undefined].includes(field.personalPageState)
          )
      );

      setFoundRecordTableRows({ ...response, tableRowFieldValues: filteredRows });
      setTable(table);
    }
  }, [apiToUse, dispatch, item.itemId]);

  const onCloseDialog = useCallback(() => setFoundRecordTableRows(undefined), []);

  return { onGetItemFields, foundRecordTableRows, table, onCloseDialog };
};
