import {
  BaseUserFieldProps,
  Calendar,
  Choice,
  Field,
  PersonaEntity,
  RecordTableConfiguration,
  ResourceType,
  TableRowSchedulingMode,
  TaskAssignableResource,
  TaskTableAssignment
} from "enada-common";
import React, { FC, ReactNode, createRef, useEffect, useMemo, useRef, useState } from "react";
import "../../../../theme/bryntumcustomtheme.scss";
import generateTaskTableColumns from "../utils/generateTaskTableColumns";
import {
  HORIZONTAL_SCROLL_HEIGHT,
  ROW_HEIGHT,
  TOOLBAR_HEIGHT
} from "../../tableutils/taskTableUtils";
import { ganttConfig, timelineConfig } from "../GanttConfig";
import TaskTableToolbar from "../toolbar/UserTaskTableToolbar";
import "./userscheduletable.scss";
import "@bryntum/core-thin/core.material.css";
import "@bryntum/grid-thin/grid.material.css";
import "@bryntum/gantt-thin/gantt.material.css";
import "@bryntum/scheduler-thin/scheduler.material.css";
import "@bryntum/schedulerpro-thin/schedulerpro.material.css";
import { Divider, Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MgtTemplateProps, Person } from "@microsoft/mgt-react";
import StandardBoard, { StandBoardCardItem } from "../../board/UserTableBoard";
import { AllFieldsTaskModel } from "./AllFieldsTaskModel";
import hideColumns from "../../tableutils/hideColumns";
import { TaskCalendarModel } from "./TaskCalendarModel";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import TaskReadOnlyContext from "./TaskContext";
import { TaskResourceModel } from "./TaskResourceModel";
import {
  AssignmentStore,
  CalendarModel,
  Gantt,
  ProjectModel,
  TaskStore
} from "@bryntum/gantt-thin";
import { ResourceModelConfig, TaskModel } from "@bryntum/taskboard-thin";
import { ColumnStore } from "@bryntum/grid-thin";
import { BryntumGantt } from "@bryntum/gantt-react-thin";
import { AsyncHelper, Store, WidgetHelper } from "@bryntum/core-thin";
import { BryntumTimeline } from "@bryntum/schedulerpro-react-thin";
import { PercentMixinProjectModel } from "./PercentMixinProjectModel";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useToggleAutoHeight } from "../../tableutils/useToggleAutoHeight";
import { dynamicColumns } from "../../tableutils/isColumnDynamic";
import EdisonTypography from "../../../../edison/typography/EdisonTypography";
import { TFunction } from "i18next";

export interface UserScheduleTableProps extends BaseUserFieldProps {
  t: TFunction;
  addCardText: string;
  addTaskText: string;
  boardViewText: string;
  columns: Field[];
  totalRows: number;
  data: any[];
  dependenciesData: Record<string, number>[];
  editCardText: string;
  peopleList: PersonaEntity[];
  redoText: string;
  removeCardText: string;
  showBoardView: boolean;
  showCriticalPaths: boolean;
  showTimeline: boolean;
  taskViewText: string;
  timeZone: string;
  undoText: string;
  viewCardText: string;
  zoomInText: string;
  zoomOutText: string;
  zoomToFitText: string;
  boardColumns?: Choice[];
  columnFilter?: boolean;
  onChange?: (event: any) => void;
  onImportMpp?: (fileList: FileList) => FileList;
  refreshRequired: boolean;
  convertingMpp: boolean;
  mppResponseType: string;
  onProjectChange?: (event: any) => void;
  onEdiClick?: () => void;
  columnField?: string;
  toolbarModule?: ReactNode;
  resources?: TaskAssignableResource[];
  calendars?: Calendar[];
  assignments?: TaskTableAssignment[];
  cardItems?: StandBoardCardItem[];
  hiddenColumns: Field[];
  currentViewColumnOrder?: any[];
  tableConfig?: RecordTableConfiguration;
  onRollupTableChanged?: (startDate: any, endDate: any, percentDone: number) => void;
  rollupTableChanged?: boolean;
  projectStartDate?: Date;
  required?: boolean;
  newData: { add?: TaskModel[]; remove?: TaskModel[] };
  rowsToUpdate: any[];
  noDatesFromConfig?: boolean;
  hideTableConfigModal?: boolean;
  onError?: any;
  hideEdi?: boolean;
}

const UserScheduleTable: FC<UserScheduleTableProps> = ({
  t,
  data,
  dependenciesData,
  columns,
  totalRows,
  resources,
  calendars,
  assignments,
  showCriticalPaths,
  showTimeline: initShowTimeline,
  showBoardView,
  boardColumns,
  onChange,
  onImportMpp,
  refreshRequired,
  convertingMpp,
  mppResponseType,
  onProjectChange,
  onEdiClick,
  label,
  readOnly,
  columnField,
  toolbarModule,
  cardItems,
  hiddenColumns,
  currentViewColumnOrder,
  tableConfig,
  onRollupTableChanged,
  rollupTableChanged,
  projectStartDate,
  required,
  timeZone,
  newData,
  description,
  rowsToUpdate,
  noDatesFromConfig,
  hideTableConfigModal,
  onError,
  hideEdi
}) => {
  const ganttRef = useRef<any>();

  const [showTimeline, setShowTimeline] = useState(initShowTimeline);
  const [fullScreen, setFullScreen] = useState(false);
  const [hasLoaded, sethasLoaded] = useState(false);
  const [loadStarted, setLoadStarted] = useState(false);
  const [hasSelectedData, setHasSelectedData] = useState<boolean>(false);

  const { toggleAutoHeight, autoHeight } = useToggleAutoHeight(
    columns,
    ganttRef.current?.instance,
    label
  );
  const columnsRef = useRef();

  const getColumns = () => {
    // Taken From this https://github.com/facebook/react/issues/14490#issuecomment-454973512
    // allows to only run the generation of columns onces
    const currentColumns = columnsRef.current;
    if (currentColumns !== undefined) {
      return currentColumns;
    }
    const newColumns = generateTaskTableColumns(columns);
    columnsRef.current = newColumns;
    return newColumns;
  };
  const todaysDate = new Date();

  const ResourceCreationTemplate = (props: MgtTemplateProps) => {
    const { person, personImage, resource } = props.dataContext;
    project.current.resourceStore.addAsync(
      {
        id: resource.id,
        name: person.displayName,
        calendar: resource.calendar ?? null,
        imageUrl: personImage,
        userId: resource.userId ?? null
      } as Partial<ResourceModelConfig>,
      true
    );
    return null;
  };

  const adjustProjectStartDate = async () => {
    const dates = (project.current.taskStore as any).getTotalTimeSpan();
    if (!dates.startDate && !dates.endDates) return;

    ganttRef?.current?.instance.setStartDate(dates.startDate);
    ganttRef?.current?.instance.setEndDate(dates.endDate);
  };

  const onDataReady = async () => {
    if (project.current.stm.disabled === true) {
      project.current.stm.enable();
    }

    if (!hasLoaded && ganttRef.current !== undefined) {
      //The first time this event fires is when our data has been loaded and calculations been run after the table mounts
      sethasLoaded(true);
    }

    // Temporary workaround, this functionality should be added to project configuration soon
    // // https://github.com/bryntum/support/issues/2274
    if (noDatesFromConfig) return;
    adjustProjectStartDate();
  };

  const onUpdate = ({ record: task, changes }: any) => {
    // Change percentDone when changing status via task board
    if (changes.status) {
      switch (task.status) {
        case "todo":
          task.percentDone = 0;
          break;
        case "inprogress":
          task.percentDone = 50;
          break;
        case "done":
          task.percentDone = 100;
          break;
      }
    }
  };

  const addTaskData = async () => {
    if (data.length) {
      setLoadStarted(true);
      try {
        project.current.taskStore.data = data;
        //await project.current.taskStore.add(data);  // MONOREPO: This seemed to be throwing an error so using data = data instead
      } catch (e) {
        console.log("catch adding data", project.current, e);
        if (onError) {
          onError(e);
        }
      }
    }
  };

  const addCalendarData = () => {
    if (calendars && project.current.calendarManagerStore.allCount === 0) {
      const formattedCalenders = calendars.map(calendar => ({
        ...calendar,
        unspecifiedTimeIsWorking: false
      })) as CalendarModel[];

      project.current.calendarManagerStore.add(formattedCalenders, true);
    }
  };

  const addAssignmentsData = async () => {
    if (assignments) {
      await project.current.assignmentStore.addAsync(assignments, true);
    }
  };

  const addDependenciesData = async () => {
    if (dependenciesData) {
      // Filter out dependencies that already exist in the store to avoid duplicates
      dependenciesData = dependenciesData.filter(
        newDependency =>
          !project.current.dependencyStore.allRecords.some((existingDependency: any) => {
            return (
              existingDependency.data.fromEvent === newDependency.fromEvent &&
              existingDependency.data.toEvent === newDependency.toEvent &&
              existingDependency.data.type === newDependency.type
            );
          })
      );

      await project.current.dependencyStore.addAsync(dependenciesData, true);
    }
  };

  useEffect(() => {
    project.current.on(
      "schedulingConflict",
      ({
        schedulingIssue,
        continueWithResolutionResult
      }: {
        schedulingIssue: any;
        continueWithResolutionResult: any;
      }) => {
        // if the resolution dialog is diaplyed, we need to set hasLoaded to true
        // to ensure any resolution outcomes are saved
        sethasLoaded(true);
        continueWithResolutionResult();
      }
    );
  }, []);

  // useEffect(() => {
  //   // default start date and end date for the project even if 0 tasks
  //   if (noDatesFromConfig) return;
  //   project.current.startDate = new Date(
  //     todaysDate.getUTCFullYear(),
  //     todaysDate.getUTCMonth(),
  //     todaysDate.getUTCDate(),
  //     9
  //   );
  //   project.current.endDate = new Date(
  //     todaysDate.getUTCFullYear(),
  //     todaysDate.getUTCMonth(),
  //     todaysDate.getUTCDate(),
  //     17
  //   );
  // }, []);

  useEffect(() => {
    //Live update values in row
    rowsToUpdate?.forEach(row => {
      const { rowId, ...rest } = row;
      const task = project.current.taskStore.getById(rowId);
      task.set({ ...rest });
    });
  }, [rowsToUpdate]);

  useEffect(() => {
    if (loadStarted) return;
    loadData();
  }, []);

  useEffect(() => {
    if (refreshRequired) {
      if (project.current.taskStore.records.length) {
        project.current.taskStore.removeAll();
      }
      loadData();
    }
  }, [refreshRequired]);

  const loadData = async () => {
    dayjs.extend(utc);

    await addTaskData();

    addCalendarData();

    // we need to ensure this is ran as our default calendar hoursPerDay, daysPerWeek and daysPerMonth may not match
    // Bryntumns default of 24 hours and screw up the schedule
    updateCalendar();

    addAssignmentsData();

    addDependenciesData();
  };

  useEffect(() => {
    if (noDatesFromConfig) return;
    updateCalendar();
  }, [tableConfig?.taskTableCalendarId]);

  useEffect(() => {
    if (noDatesFromConfig) return;
    if (tableConfig?.schedulingDirection) {
      project.current.direction = tableConfig?.schedulingDirection;
    }
  }, [tableConfig?.schedulingDirection]);

  useEffect(() => {
    if (noDatesFromConfig) return;
    if (!project.current.startDate) {
      if (tableConfig?.startDate) {
        project.current.startDate = new Date(tableConfig.startDate);
      }
    }
  }, [tableConfig?.startDate]);

  useEffect(() => {
    if (noDatesFromConfig) return;
    if (tableConfig?.endDate) {
      project.current.endDate = new Date(tableConfig.endDate);
    }
  }, [tableConfig?.endDate]);

  const updateCalendar = async () => {
    if (!calendars?.length) return;

    let calendarId = tableConfig?.taskTableCalendarId;
    if (calendarId === undefined) {
      calendarId = calendars?.find(c => c.isDefault)?.id;
    }

    let updateSchedule = false;

    // bryntumCalendar and edisonCalendar below are the same calendars but come from different sources.
    // edisonCalendar comes from the calendars prop whereas bryntumCalendar si from the Calendar store.
    // The calendar store doesn't contain hoursPerday so we need to grab that from the edisonCalendar
    const edisonCalendar = calendars?.find(c => c.id === calendarId);
    if (
      edisonCalendar &&
      edisonCalendar?.configuration?.hoursPerDay !== project.current.hoursPerDay
    ) {
      project.current.hoursPerDay = edisonCalendar.configuration.hoursPerDay;
      updateSchedule = true;
    }
    if (edisonCalendar?.configuration?.days?.length !== project.current.daysPerWeek) {
      project.current.daysPerWeek = edisonCalendar?.configuration?.days?.length;
      updateSchedule = true;
    }

    const averageDaysPerMonth = (edisonCalendar?.configuration?.days?.length * 52) / 12;
    if (!isNaN(averageDaysPerMonth) && averageDaysPerMonth !== project.current.daysPerMonth) {
      project.current.daysPerMonth = Math.round(averageDaysPerMonth) ?? 1;
      updateSchedule = true;
    }

    // if both project and table config undefined then we want to set the tables project calendar to the default edison calendar
    const projectAndTableConfigCalendarUndefined =
      tableConfig?.taskTableCalendarId === undefined && project.current?.calendar?.id === undefined;
    if (
      (tableConfig?.taskTableCalendarId !== undefined &&
        tableConfig?.taskTableCalendarId !== project.current?.calendar?.id) ||
      projectAndTableConfigCalendarUndefined
    ) {
      const bryntumCalendar = project.current.calendars.find(c => c.id === calendarId);
      await project.current.setCalendar(bryntumCalendar as CalendarModel);
      updateSchedule = false;
    }

    // setCalendar above causes the scheduling to run automatically, so we only want to manually
    // trigger a schedule if a chnage has been made and the calendar hasn't been updated
    if (updateSchedule) {
      await project.current.commitAsync();
    }
  };

  const onEmptyCalendar = (props: any) => {
    // This method is needed to override the default bryntum behaviour for handling  calendar conflicts,
    // specifically conflicts between a resources calendar and the tables calendar
    // The default bryntum behaviour was to change the whole tables calendar to one hard coded by bryntum, which broke our tables
    const { schedulingIssue, continueWithResolutionResult, source } = props;
    const record: AllFieldsTaskModel = schedulingIssue.event;

    const calendarOne = schedulingIssue.calendars.shift().data;
    const calendarTwo = schedulingIssue.calendars.pop().data;
    const conflictMessage = `The calendar ${calendarTwo.name} from the assigned resource has no working time in common with the calendar ${calendarOne.name} from the table.`;

    const editor = WidgetHelper.openPopup(popupAnchorRef.current, {
      cls: "conflict-resolution-popup",
      closeAction: "destroy",
      floating: true,
      centered: true,
      autoClose: false,
      header: {
        dock: "top",
        title: "Resource calendar conflicts with table Calendar"
      },
      html: conflictMessage,
      items: {
        resolution: {
          name: "resolution",
          type: "radiogroup",
          label: "Resolve Conflict",
          value: "A",
          options: {
            A: "Ignore Resource Calendar",
            B: "Cancel Assignment"
          }
        },
        apply: {
          type: "button",
          text: "Save",
          color: "b-green",
          onClick: () => {
            if (editor.widgetMap.resolution.value === "A") {
              record.setIgnoreResourceCalendar(true);
            } else {
              record.set("assignments", []);
            }
            continueWithResolutionResult("Resume");
            editor.close();
          }
        }
      }
    });
    return false;
  };
  const project = useRef<ProjectModel>(
    new PercentMixinProjectModel({
      enableProgressNotifications: true,
      calendar: tableConfig?.taskTableCalendarId?.toString(),
      taskModelClass: AllFieldsTaskModel,
      resourceModelClass: TaskResourceModel,
      taskStore: {
        wbsMode: "auto", // Used when reordering tasks so they get saved correctly
        syncDataOnLoad: true,
        // only add fields that aren't mapped in the AllFieldsTaskModel model
        fields: columns.filter(f => f.name.endsWith("-e365")).map(f => f.name),
        autoTree: true,
        transformFlatData: true,
        listeners: {
          update: onUpdate
        }
      },
      onDataReady: onDataReady,
      onEmptyCalendar: onEmptyCalendar,
      calendarModelClass: TaskCalendarModel
    }) as ProjectModel
  );

  useEffect(() => {
    if (noDatesFromConfig) return;
    if (rollupTableChanged && onRollupTableChanged) {
      onRollupTableChanged(
        project.current.startDate,
        project.current.endDate,
        (project.current as any).percentDone
      );
    }
  }, [rollupTableChanged]);

  useEffect(() => {
    const gantt = ganttRef?.current?.instance;
    if (!gantt) {
      return;
    }
    gantt.rowHeight = ROW_HEIGHT;
  }, []);

  useEffect(() => {
    if (!currentViewColumnOrder || currentViewColumnOrder.length < 1) {
      return;
    }
    const columnStore = ganttRef?.current?.instance?.columns as ColumnStore;
    if (columnStore === undefined) return;

    const ordered = [...(currentViewColumnOrder ?? [])];
    ordered.sort((l: any, r: any) => {
      return l.order > r.order ? -1 : l.order < r.order ? 1 : 0;
    });
    let last = columnStore.getById(ordered[0].fieldId);
    for (let i = 1; i < ordered.length - 1; i++) {
      const columnToMove = columnStore.getById(ordered[i].fieldId);
      if (columnToMove && last) {
        columnStore.move(columnToMove, last);
        last = columnToMove;
      }
    }
  }, [currentViewColumnOrder]);

  useEffect(() => {
    if (!newData.add?.length && !newData.remove?.length) return;

    const gantt = ganttRef?.current?.instance;
    const taskStore = gantt?.taskStore as TaskStore;
    const project = gantt?.project as ProjectModel;
    const commitChanges = async () => await project.commitAsync();

    if (newData.add?.length) {
      // ensure all rows are added with defaults or the add will fail
      const transformedTasks = newData.add.map(nd => ({
        ...nd,
        ...getNewRowEdisonFieldsWithDefaults(),
        durationUnit: "days"
      }));
      transformedTasks.forEach((row, i) => {
        const existingTask = taskStore.getById(row.id);
        if (!existingTask) {
          taskStore.add({
            ...row,
            wbsValue: (taskStore.max("wbsValue", taskStore.allRecords) as number) + 1 // set wbsValue to the next highest value
          });
        }
      });
    } else if (newData.remove?.length) {
      newData.remove.forEach(task => {
        const existingTask = taskStore.getById(task.id);
        if (existingTask) {
          taskStore.remove(existingTask);
        }
      });
    }
    // we need to trigger the comitt as the table mapper can not handle bulk adds
    commitChanges();
  }, [newData]);

  const isIllegitimateAssignmentChange = (event: any) =>
    event.store instanceof AssignmentStore && event.source && !event.source.navigationEvent;

  const taskTableOnDataChange = async (event: any) => {
    AsyncHelper.animationFrame();
    // Only process the on change events if the initial calculations have run
    if (!hasLoaded || readOnly) return;

    // Only allow user initiated assignment changes to prevent project save on load
    if (isIllegitimateAssignmentChange(event)) return;
    if (
      event.records &&
      event.records.length > 0 &&
      event.records[0] instanceof ProjectModel &&
      event.store.count === 0
    ) {
      // If we have deleted all the rows from a task table(e.g. store.count ===0 )
      //and the event is ProjecModel event then set the end and start dates of the project to null
      // in order to prevent the project from displaying dates with no tasks
      project.current.set("startDate", null);
      project.current.set("endDate", null);
    }
    if (onProjectChange && event.record instanceof ProjectModel) {
      onProjectChange(event);
    } else if (onChange) {
      onChange(event);
    }
  };

  const onFocusOut = (e: any) => {
    if (!e.backwards) return;
    const selected = ganttRef.current?.instance.selectedCell as any;
    if (!selected) return;
    ganttRef.current?.instance.deselectCell(selected);
  };

  const getNewRowEdisonFieldsWithDefaults = () =>
    columns
      .filter(column => column.name.endsWith("-e365"))
      .reduce(
        (next, current) => ({
          ...next,
          [current.name]: null
        }),
        {}
      );

  const getNewRowBryntumFieldsWithDefaults = () => ({
    name: "New task",
    startDate:
      project.current.startDate ??
      new Date(todaysDate.getUTCFullYear(), todaysDate.getUTCMonth(), todaysDate.getUTCDate(), 9),
    duration: project.current.hoursPerDay,
    durationUnit: "hours",
    effort: 0,
    schedulingMode: tableConfig?.schedulingMode ?? TableRowSchedulingMode.FixedDuration
  });

  const onTaskAdd = async () => {
    const gantt = ganttRef?.current?.instance;

    const edisonFields = getNewRowEdisonFieldsWithDefaults();
    const bryntumFields = getNewRowBryntumFieldsWithDefaults();
    const added = gantt.taskStore.add({ ...edisonFields, ...bryntumFields });

    // run propagation to calculate new task fields
    await gantt.project.commitAsync();

    // scroll to the added task
    await gantt.scrollRowIntoView(added);

    gantt.features.cellEdit.startEditing({
      record: added,
      field: "name"
    });
  };

  const buildResources = useMemo(() => {
    const resourcesMap = resources?.map((r, i) => {
      if (r.type === ResourceType.AAD) {
        return (
          <Person
            key={i}
            hidden
            userId={r.userId ? r.userId : ""}
            templateContext={{
              resource: r
            }}
          >
            <ResourceCreationTemplate template="default" />
          </Person>
        );
      } else {
        project.current.resourceStore.addAsync(
          {
            id: r.id,
            name: r.name,
            // TODO: change calendar prop to calendarId on BE for consistency
            calendar: (r as any).calendar ?? null // set to null to prevent unnessasary event being triggered
          } as Partial<ResourceModelConfig>,
          true
        );
      }
    });
    return resourcesMap;
  }, [resources]);

  useEffect(() => {
    hideColumns(ganttRef?.current?.instance?.columns as ColumnStore, hiddenColumns);
  }, [hiddenColumns]);

  // Removed data wrapping in schedule table for the v1.4.0 release
  // useEffect(() => {
  //   // Once the gantt is loaded, synchronize the tables autoheight with the one in local storage
  //   if (!hasLoaded) return;
  //   if (!autoHeight) return;
  //   toggleAutoHeight(autoHeight);
  // }, [hasLoaded]);

  const popupAnchorRef = createRef<any>();

  return (
    <>
      {buildResources}
      <div ref={popupAnchorRef} className={`task-table-root ${fullScreen && "fullscreen"}`}>
        <div className={`${fullScreen && "sticky-toolbar"}`}>
          <TaskTableToolbar
            t={t}
            gantt={ganttRef}
            project={project.current}
            readOnly={readOnly}
            columns={columns}
            showTimeline={showTimeline}
            setShowTimeline={setShowTimeline}
            toolbarModule={toolbarModule}
            taskAddCallback={onTaskAdd}
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            onChange={onChange}
            onImportMpp={onImportMpp}
            convertingMpp={convertingMpp}
            mppResponseType={mppResponseType}
            onEdiClick={onEdiClick}
            tableConfig={tableConfig}
            calendars={calendars ?? []}
            hasSelectedData={hasSelectedData}
            hideTableConfigModal={hideTableConfigModal}
            label={label}
            hideEdi={hideEdi}
            onDataChange={taskTableOnDataChange}
            titleModule={
              <Stack direction="row" spacing={1}>
                <EdisonTypography
                  title={label}
                  sx={{
                    color: required && data.length === 0 ? "red" : undefined
                  }}
                  variant={"data"}
                />
                {description && (
                  <Tooltip title={description} arrow placement="right" className="table-info-icon">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                )}
                {readOnly && <LockOutlinedIcon fontSize="small" className="table-readonly-icon" />}
              </Stack>
            }
          />
        </div>
        <Divider />
        <div className={`task-view ${showBoardView && "visible"}`}>
          {showBoardView && (
            <StandardBoard
              boardColumns={boardColumns as Choice[]}
              tableColumns={columns as Field[]}
              project={project.current as any}
              columnField={columnField as string}
              getDefaultRow={(newId: string) => ({
                TaskUniqueId: newId,
                name: "New task",
                duration: 1,
                startDate: new Date(),
                status: "todo"
              })}
              cardItems={[
                { type: "text", field: "Name", location: "header" },
                { type: "tags", field: "status", location: "body" },
                { type: "progress", field: "percentDone", location: "body" },
                { type: "date", field: "startDate", location: "footer" },
                { type: "text", field: "duration", location: "footer" },
                ...(cardItems ?? [])
              ]}
            />
          )}
        </div>
        <Stack className={`task-view ${!showBoardView && "visible"}`}>
          {showTimeline && (
            <Stack>
              <BryntumTimeline {...timelineConfig} project={project.current as any} />
              <Divider flexItem />
            </Stack>
          )}
          <div className="task-table-container enada-bryntum-grid">
            <TaskReadOnlyContext.Provider value={Boolean(readOnly)}>
              <BryntumGantt
                {...ganttConfig}
                displaySchedulingIssueResolutionPopup={true}
                timeZone={timeZone}
                columns={getColumns()}
                project={project.current}
                ref={ganttRef}
                maxHeight={"calc(100vh - 65px)"}
                height={`${totalRows * ROW_HEIGHT + TOOLBAR_HEIGHT + HORIZONTAL_SCROLL_HEIGHT}px`}
                rowHeight={ROW_HEIGHT}
                onFocusOut={onFocusOut}
                onDataChange={taskTableOnDataChange}
                readOnly={readOnly}
                filterFeature={true}
                indicatorsFeature={true}
                criticalPathsFeature={{
                  disabled: true
                }}
                // features={
                //   {
                //     indicators: {
                //       items: {
                //         earlyDates: false,
                //         lateDates: false,
                //         constraintDate: false
                //       }
                //     }
                //   }
                // }
                onSelectionChange={e => setHasSelectedData(e.selection.length > 0)}
                newTaskDefaults={{
                  ...getNewRowEdisonFieldsWithDefaults(),
                  ...getNewRowBryntumFieldsWithDefaults()
                }}
              />
            </TaskReadOnlyContext.Provider>
          </div>
        </Stack>
      </div>
    </>
  );
};

export default UserScheduleTable;
export { UserScheduleTable };
