import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi
} from "@reduxjs/toolkit/query/react";
import { PublicClientApplication, AuthenticationResult } from "@azure/msal-browser";
import { graphConfig, graphRequest, msalConfig } from "config/authConfig";
import { User } from "@microsoft/microsoft-graph-types";

const msalInstance = new PublicClientApplication(msalConfig);

type GraphBaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export const graphBaseQuery: GraphBaseQuery = async (args, api, extraOptions) => {
  const currentAccount = msalInstance.getAllAccounts();
  const request = {
    scopes: graphRequest.scopes,
    account: currentAccount[0]
  };

  try {
    const response: AuthenticationResult = await msalInstance.acquireTokenSilent(request);
    if (!response) {
      await msalInstance.acquireTokenRedirect(request);
      return { error: { status: 401, data: "Token acquisition failed" } };
    }

    const accessToken = response.accessToken;

    const headers = new Headers((args as any)?.extraHeaders || {});
    headers.append("Authorization", `Bearer ${accessToken}`);

    const modifiedArgs = typeof args === "string" ? { url: args } : { ...args };
    modifiedArgs.headers = headers;

    const result = await fetchBaseQuery({
      baseUrl: `${graphConfig.graphEndpoint}`
    })(modifiedArgs, api, extraOptions);

    return result;
  } catch (error) {
    console.log(error);
    return { error: { status: 500, data: "Request failed" } };
  }
};

export const graphAPI = createApi({
  reducerPath: "graphAPI",
  baseQuery: graphBaseQuery,
  tagTypes: ["User"],
  // need to keep this as is for TS to not complain and endpoints to inject correctly
  endpoints: builder => ({
    getUser: builder.query<User, void>({
      query: () => `/me`,
      providesTags: ["User"]
    }),

    getUserPhoto: builder.query<string, void>({
      query: () => ({
        url: `me/photo/$value`,
        responseHandler: response => response.blob() // Handle response as blob
      }),
      transformResponse: (response: Blob) => {
        const win = window.URL || window.webkitURL;
        return win.createObjectURL(response); // Convert blob to object URL
      },
      providesTags: ["User"]
    })
  })
});
