import { Alert, Button, Paper, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import "./workflowpopulationview.scss";
import { EdisonTypography, Loading } from "enada-components";
import { useTranslation } from "react-i18next";
import HistoryIcon from "@mui/icons-material/History";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getLivePreviewTableAsync } from "../../../store/slices/formDesignerSlice";
import { Node } from "reactflow";
import {
  getPopulationFieldValuesAsync,
  getPopulationRowsAsync,
  getPopulationTableConfigsAsync,
  getPopulationTableTotalsAsync,
  resetPopulationSlice,
  savePopulationFieldValuesAsync,
  savePopulationRowsAsync,
  savePopulationTableConfigsAsync,
  selectPopulationBackendValues,
  selectPopulationChangeMade,
  selectPopulationFrontendFieldValues,
  selectPopulationObject,
  setDataPopulation,
  setPopulationFrontendValues
} from "../../../store/slices/dataPopulationSlice";
import { resetRecordSlice, updateSaveStatus } from "../../../store/slices/recordSlice";
import { selectAllFields } from "../../../store/slices/fieldsSlice";
import {
  WorkflowDesignerView,
  selectCurrentElement,
  selectWorkflowCurrentNodePopulation,
  setWorkflowDesignerView,
  updateElement
} from "../../../store/slices/workflowSlice";
import { getMyResourcesAsync } from "../../../store/slices/resourcesSlice";
import { enumerateTables } from "../../../utils/enumerateTables";
import { Form, WorkflowEventAction, WorkflowStageEvent } from "enada-common";
import WorkflowLivePreview from "../workflowlivepreview/WorkflowLivePreview";
import {
  parseFormFieldsToBackend,
  parseFormFieldsToFrontend
} from "../../../store/slices/parseFormData";
import { updateNodeCustomData } from "../utils/updateNodeCustomData";
import { sleep } from "../../../utils/sleep";
import WorkflowLivePreviewConfirmation from "../workflowlivepreviewconfirmation/WorkflowLivePreviewConfirmation";
import {
  resetRecordTableSlice,
  selectActiveSaveSlot,
  selectHasTableConfigurationChanges,
  selectTableConfigs,
  selectTableOperations
} from "../../../store/slices/recordTableSlice";
import { mapRecordToPopulationTableConfigs } from "../../../utils/mappers/populationToRecordMappers";
import { useGetFieldsQuery, useGetFormQuery } from "services/api";

const WorkflowPopulationView = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectCurrentElement);

  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });
  const { data: form } = useGetFormQuery(currentNode?.data.formId, {
    skip: !currentNode?.data.formId
  });

  const fieldValues = useAppSelector(selectPopulationFrontendFieldValues);
  const activeSaveSlot = useAppSelector(selectActiveSaveSlot);

  const updatedPopulation = useAppSelector(selectPopulationObject);
  const backendPopulationValues = useAppSelector(selectPopulationBackendValues);
  const tableOperations = useAppSelector(selectTableOperations);
  const tableConfigs = useAppSelector(selectTableConfigs);
  const hasTableConfigurationChanges = useAppSelector(selectHasTableConfigurationChanges);
  const population = useAppSelector(selectWorkflowCurrentNodePopulation);
  const changeMade = useAppSelector(selectPopulationChangeMade);
  const fieldsOrRowsChanged = changeMade || tableOperations.length > 0;
  const [loading, setLoading] = useState(false);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  const [resetConfirmOpen, setResetConfirmOpen] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetPopulationSlice());
      dispatch(resetRecordSlice());
      dispatch(resetRecordTableSlice());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!form) return;
    if (population === undefined) return;

    dispatch(getMyResourcesAsync());

    dispatch(setDataPopulation(population));
    enumerateTables(form as Form, table => {
      dispatch(getLivePreviewTableAsync(table.tableId as number));
      dispatch(
        getPopulationTableTotalsAsync({
          tableId: table.tableId as number,
          populationId: population.id as number
        })
      );
    });
    if (!population.id) return;
    dispatch(getPopulationFieldValuesAsync(population.id));
    dispatch(getPopulationRowsAsync(population.id));
    dispatch(getPopulationTableConfigsAsync(population.id));
  }, [dispatch, form, population]);

  useEffect(() => {
    if (backendPopulationValues === undefined) return;

    if (!form) return;
    const parsed = parseFormFieldsToFrontend(form as Form, fields, backendPopulationValues);
    const parsedWithIds = parsed?.map(value => ({
      ...value,
      id: backendPopulationValues?.find(backendValue => backendValue.fieldId === value.fieldId)?.id
    }));
    dispatch(setPopulationFrontendValues(parsedWithIds));
  }, [backendPopulationValues, dispatch, fields, form]);

  useEffect(() => {
    if (!loading) return;

    const defferedChange = async () => {
      // Needed because we have to onMount and reMount the bryntum tables for their data to be reset
      await sleep(200);
      setLoading(false);
    };
    defferedChange();
  }, [loading]);

  const onClose = () => {
    dispatch(setWorkflowDesignerView(WorkflowDesignerView.StageView));
  };
  return (
    <Stack className="workflow-population-view-root" spacing={3}>
      <Paper>
        <Stack className="top-bar" spacing={1}>
          <Stack direction="row" className="title-container">
            <EdisonTypography title={t("configurePrepopulatedData")} variant="h3" />
            <Stack direction="row" spacing={1}>
              <Button
                disabled={!fieldsOrRowsChanged}
                startIcon={<HistoryIcon />}
                onClick={() => {
                  setResetConfirmOpen(true);
                }}
              >
                {t("resetAllChanges")}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (population?.id === undefined) {
                    onClose();
                    return;
                  }
                  const parsed = parseFormFieldsToBackend(
                    fieldValues,
                    population.id,
                    "dataPopulationId"
                  );
                  if (parsed) {
                    dispatch(
                      savePopulationFieldValuesAsync({
                        id: population.id,
                        values: parsed
                      })
                    );
                  }
                  if (tableOperations?.length) {
                    dispatch(updateSaveStatus("rows"));

                    // Use the active save slot to work out which table operations to save. activeSaveSlot has not been affected by the swap.
                    dispatch(
                      savePopulationRowsAsync({
                        activeSaveSlot,
                        populationId: population?.id as number
                      })
                    );
                  }
                  if (hasTableConfigurationChanges) {
                    dispatch(
                      savePopulationTableConfigsAsync({
                        populationId: population?.id as number,
                        tableConfigs: mapRecordToPopulationTableConfigs(tableConfigs)
                      })
                    );
                  }

                  const updatedEvents = (currentNode?.data.events as WorkflowStageEvent[]).map(
                    event =>
                      event.action === WorkflowEventAction.PopulateData
                        ? { ...event, workflowDataPopulation: updatedPopulation }
                        : event
                  );
                  dispatch(
                    updateElement(
                      updateNodeCustomData("events", updatedEvents, currentNode as Node)
                    )
                  );
                  dispatch(resetPopulationSlice());
                  dispatch(resetRecordSlice());
                  dispatch(resetRecordTableSlice());
                  onClose();
                }}
              >
                {t("save")}
              </Button>

              <Button
                variant="outlined"
                onClick={() => {
                  setCloseConfirmOpen(true);
                }}
              >
                {t("cancel")}
              </Button>
            </Stack>
          </Stack>
          <Alert severity="info">{t("viewingWorkflowLivePreviewMessage")}</Alert>
        </Stack>
      </Paper>
      {loading ? (
        <Loading size={50} />
      ) : (
        <WorkflowLivePreview tabs={form?.containers ?? []} source={"dataPrepopulation"} />
      )}
      <WorkflowLivePreviewConfirmation
        open={resetConfirmOpen}
        onClose={() => setResetConfirmOpen(false)}
        title={t("resetAllChanges")}
        alertMessage={t("resetAllChangesMessage")}
        actionText={t("resetAllChanges")}
        action={() => {
          dispatch(resetPopulationSlice());
          dispatch(resetRecordSlice());
          dispatch(resetRecordTableSlice());
          if (!population?.id) return;
          dispatch(getPopulationFieldValuesAsync(population.id));
          dispatch(getPopulationRowsAsync(population.id));
          dispatch(getPopulationTableConfigsAsync(population.id));
          setLoading(true);
          setResetConfirmOpen(false);
        }}
      />
      <WorkflowLivePreviewConfirmation
        open={closeConfirmOpen}
        onClose={() => setCloseConfirmOpen(false)}
        title={t("discardUnsavedChanges")}
        alertMessage={t("confirmUnsavedChangesDiscardMessage")}
        actionText={t("discardChanges")}
        action={() => {
          dispatch(resetRecordSlice());
          dispatch(resetPopulationSlice());
          dispatch(resetRecordTableSlice());
          setCloseConfirmOpen(false);
          onClose();
        }}
      />
    </Stack>
  );
};

export default WorkflowPopulationView;
