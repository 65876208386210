import { FieldValue } from "./FieldValue.model";
import { RecordRole } from "./RecordTemplate.model";
import { PermissionType } from "./PermissionType.model";
import { WorkflowStageViewState } from "./Workflow.model";
import { PersonaEntity } from "../frontend/shared/PersonaEntity.model";

// Record clashes with ts Record hence name
export interface BaseRecord {
  approvedCount?: number;
  autoApproveUpdates?: boolean;
  averageRating?: number;
  childRecordCount?: number;
  configuration?: { [key: string]: unknown } | null;
  created?: string;
  createdBy?: string | null;
  currency?: string | null;
  description?: string | null;
  displayName?: string | null;
  endDate?: string | null;
  favouriteCount?: number;
  favourited?: boolean;
  fieldValues?: RecordFieldValue[] | null;
  id?: number;
  imageUrl?: string | null;
  isDeleted?: boolean;
  maxReviewersCount?: number;
  modified?: string;
  modifiedBy?: string | null;
  owners?: PersonaEntity;
  canCreateAssociations?: boolean;
  quartersFormat?: string | null;
  percentDone?: number;
  readOnly?: boolean;
  recordAssociations?: BaseRecord[];
  recordPermissions: RecordPermission[];
  recordIdeatorsPermissions?: RecordPermission[];
  recordTemplateId: number;
  recordType: RecordType;
  rejectedCount?: number;
  requiredApproversCount?: number;
  shortDescription?: string | null;
  startDate?: string;
  tableRows?: RecordTableRow[] | null;
  tags?: string | null;
  taskTableId?: number | null;
  timeZone?: string;
  uniqueId?: string | null;
  useChildDateRange?: boolean;
  viewCount?: number;
  workflow?: number;
  workflowStage?: number;
  workflowStageState?: WorkflowStageState;
  workflowStep?: number;
  workflowViewState?: WorkflowStageViewState;
  workflowStageChanged?: string | null;
  yearStart?: number;
}

// We need to make sure these enums are in the same order as the records in the navbar
export enum RecordType {
  Programs = "Programs",
  Projects = "Projects",
  BusinessCase = "BusinessCase",
  Challenges = "Challenges",
  Ideas = "Ideas"
}

export enum WorkflowStageState {
  None = "None",
  Submitted = "Submitted",
  Approved = "Approved"
}
export enum WorkflowReviewState {
  None = "None",
  Approved = "Approved",
  Rejected = "Rejected"
}
export interface WorkflowReview {
  modified?: string;
  modifiedBy?: string;
  id?: number;
  recordId?: number;
  stage?: number;
  comments?: string;
  reviewState: WorkflowReviewState;
}
export interface WorkflowUserReview {
  workflowTasks?: WorkflowTask[];
  workflowReview?: WorkflowReview;
}
export interface WorkflowTask {
  modified?: string;
  modifiedBy?: string;
  id?: number;
  recordId?: number;
  stage?: number;
  task?: number;
  taskState?: WorkflowTaskState;
}

export interface WorkflowReviewer {
  userId?: number;
  workflowTasks?: WorkflowTask[];
  workflowReview?: WorkflowReview;
}

export enum WorkflowTaskState {
  None = "None",
  InProgress = "InProgress",
  Completed = "Completed"
}

export interface BasePermission {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  role: RecordRole;
  entityId: string;
  permissionType: PermissionType;
}

export interface RecordPermission extends BasePermission {
  recordId?: number;
}
export interface RecordFieldValue extends FieldValue {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;

  recordId?: number;
  recordVersionId?: number;
}

export interface CommonTableRow {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  tableId?: number;
  rowType?: TableRowType;
  showOnPersonalPage?: boolean;
  configuration?: { [key: string]: unknown } | null;
  dependencies?: { [key: string]: unknown } | null;
  dataVersion?: number;
}
export interface RecordTableRow extends CommonTableRow {
  recordId: number;
  tableRowFieldValues?: TableRowValue[] | null;
  tableRowPeriodicValues?: TableRowPeriodicValues[];
  assignments?: TableRowAssignmentsValues[];
  uniqueId?: string | null;
  parentUniqueId?: string | null;
  owner?: string | null;
  name?: string | null;
  note?: string | null;
  rollUp?: boolean | null;
  showInTimeline?: boolean | null;
  isMilestone?: boolean | null;
  constraintDate?: string | null;
  constraintType?: TableRowConstraintType;
  critical?: boolean | null;
  deadline?: string | null;
  duration?: number | null;
  durationUnit?: TableRowDurationType;
  startDate?: string | null;
  endDate?: string | null;
  earlyStartDate?: string | null;
  earlyEndDate?: string | null;
  lateStartDate?: string | null;
  lateEndDate?: string | null;
  effort?: number | null;
  effortUnit?: TableRowDurationType;
  effortDriven?: boolean | null;
  inactive?: boolean | null;
  manuallyScheduled?: boolean | null;
  percentDone?: number | null;
  schedulingMode?: TableRowSchedulingMode;
  totalSlack?: number | null;
  slackUnit?: TableRowDurationType;
  wbs?: string | null;
}

export interface TableRowValue extends FieldValue {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  recordTableRowId: number;
}

export interface TableRowPeriodicValues {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  year: number;
  period: number;
  decimalValue: number;
  recordTableRowId: number;
}

export interface TableRowPeriodicGrandTotal {
  tableId: number;
  totals: { [key: number]: number };
}

export interface TableRowAssignmentsValues {
  modified?: string;
  modifiedBy?: string | null;
  id?: number;
  recordTableRowId: number;
  resourceId: string | number;
  entityId: string;
  units?: number;
}

export interface PendingRecordTableRow {
  recordTableRowId?: number;
  approvalState?: ApprovalState;
  approvalStateChangedBy?: string;
  approvalStateChangedComment?: string;
  submittedEntityId?: string;
  approvedEntityId?: string;
  rejectedEntityId?: string;
  pendingRecordTableRowValue?: RecordTableRow;
}
export interface RecordsTableRowsPendingOperations {
  operationType?: ApprovalOperationType;
  pendingTableRows?: PendingTableRowIdentifier[];
}
export interface PendingTableRowIdentifier {
  id?: number;
  recordTableRowId?: number;
  submittedEntityId?: string;
}
export interface RecordTableRowsOperationRecall {
  recordTableRowId?: number;
  comment?: string;
}

export interface RowOperationType {
  operationType: OperationType;
  tableRows: RecordTableRow[];
}
export interface RecordTableRowsOperationSubmit extends RowOperationType {
  comment?: string;
}

export enum OperationType {
  AddOrUpdate,
  AddOrPatch,
  Delete,
  Submit,
  SaveForLater,
  Recall
}

export enum ApprovalOperationType {
  Approve = "Approve",
  Reject = "Reject"
}
export enum ApprovalState {
  Submitted = "Submitted",
  Approved = "Approved",
  Rejected = "Rejected",
  Saved = "Saved"
}
export enum TableRowType {
  List = "List",
  Periodic = "Periodic",
  Schedule = "Schedule"
}

export enum TableRowConstraintType {
  FinishNoEarlierThan = "FinishNoEarlierThan",
  FinishNoLaterThan = "FinishNoLaterThan",
  MustFinishOn = "MustFinishOn",
  MustStartOn = "MustStartOn",
  StartNoEarlierThan = "StartNoEarlierThan",
  StartNoLaterThan = "StartNoLaterThan"
}

export enum TableRowDurationType {
  Millisecond = "Millisecond",
  Second = "Second",
  Minute = "Minute",
  Hour = "Hour",
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Quarter = "Quarter",
  Year = "Year"
}

export enum TableRowSchedulingMode {
  Normal = "Normal",
  FixedDuration = "FixedDuration",
  FixedEffort = "FixedEffort",
  FixedUnits = "FixedUnits"
}
