import {
  Field,
  RecordTableRow,
  Table,
  TableDataType,
  TablePeriodicUnits,
  TableView,
  TaskAssignableResource,
  RecordTableConfiguration,
  Calendar,
  BaseRecord,
  TableRowValue,
  FieldValue,
  PeriodicRow,
  RequiredInType
} from "enada-common";
import { MenuItem, Select } from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { RowOrder } from "../../components/tableviewmodal/TableViewModal";
import { UserPeriodicTable, UserListTable, UserScheduleTable } from "enada-components";
import { getTableBoardCardItems } from "../parsing/getTableBoardCardItems";
import { isChangeEventValid } from "../parsing/isChangeEventValid";
import { orderColumns } from "../parsing/orderColumns";
import parsePeriodicTableChangeToBackend from "../parsing/parsePeriodicTableChangeToBackend";
import parsePeriodicTableDataToFrontend from "../parsing/parsePeriodicTableDataToFrontend";
import parseTableChangeToBackend, { RowOperationType } from "../parsing/parseTableChangeToBackend";
import parseTableDataToFrontend from "../parsing/parseTableDataToFrontend";
import {
  checkForPendingRowUpdates,
  getTaskTableSystemFieldsBryntumMap,
  parseTaskTableChangeToBackend
} from "../parsing/parseTaskTableChangeToBackend";
import {
  parseRowsToUpdateToBryntum,
  parseTaskTableDataToFrontend
} from "../parsing/parseTaskTableDataToFrontend";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  updateSystemFields,
  updateRecordFieldValue,
  setHasRollupTableChanged,
  selectHasRollupTableChanged,
  setFlyoutId,
  RecordSettings,
  setIsFlyoutOpen
} from "../../store/slices/recordSlice";
import { SYSTEM_FIELD_ID_LIMIT } from "../../config/authConfig";
import { ProjectModel, TaskModel, TaskStore } from "@bryntum/gantt-thin";
import { SystemFieldType } from "../../pages/admin/tableconfig/TableConfig";
import dayjs, { OpUnitType } from "dayjs";
import utc from "dayjs/plugin/utc";
import { isNotRequiredInTable } from "../systemFIeldScope";
import { getUniqueIdFromRow } from "../tableHelpers";
import { getRequestMsGraph } from "../../services/APIService";
import {
  MppResourceMap,
  addNewRows,
  convertMppAsync,
  removeBaseline,
  saveConvertedMppRows,
  selectConvertMppStatus,
  selectConvertedMppRows,
  selectEdiGeneratedTableRows,
  selectEdiTableId,
  selectMppEarliestStartDate,
  selectMppResponseType,
  selectMppTableId,
  setEdiTableId,
  updateTableConfiguration,
  upsertBaseline
} from "../../store/slices/recordTableSlice";
import { selectAiEnabled } from "../../store/slices/tenantSlice";
import { TFunction } from "i18next";
import { appInsights } from "../../appInsights";

export interface TableMapperProps {
  table: Table;
  fields: Field[];
  rowsInTable: RecordTableRow[];
  readOnly: boolean;
  rowsToUpdate?: RecordTableRow[];
  periodicGrandTotals?: { [key: number]: number };
  resources?: TaskAssignableResource[];
  calendars?: Calendar[];
  t: TFunction;
  project?: BaseRecord;
  onChange?: (parsed: RowOperationType[]) => void;
  deleteAction?: any;
  updateAction?: any;
  tableConfig?: RecordTableConfiguration;
  required?: boolean;
  removeUpdatedRowAction?: (uniqueId: string) => void;
  noDatesFromConfig?: boolean;
  hideTableConfigModal?: boolean;
  hideEdi?: boolean;
  refreshTables?: boolean;
}
const TableMapper: FC<TableMapperProps> = ({
  rowsInTable,
  periodicGrandTotals,
  t,
  table,
  onChange,
  readOnly,
  resources,
  calendars,
  fields,
  updateAction,
  deleteAction,
  project,
  tableConfig,
  required,
  rowsToUpdate,
  removeUpdatedRowAction,
  noDatesFromConfig,
  hideTableConfigModal,
  hideEdi,
  refreshTables
}) => {
  const dispatch = useAppDispatch();
  const [currentView, setCurrentView] = useState(table?.views ? table.views[0] : null);
  const [refreshRequired, setRefreshRequired] = useState(false);

  const hasRollupTableChanged = useAppSelector(selectHasRollupTableChanged);
  const ediRows = useAppSelector(selectEdiGeneratedTableRows);
  const ediTableId = useAppSelector(selectEdiTableId);
  const convertedMppRows = useAppSelector(selectConvertedMppRows);
  const convertMppStatus = useAppSelector(selectConvertMppStatus);
  const mppResponseType = useAppSelector(selectMppResponseType);
  const mppEarliestStartDate = useAppSelector(selectMppEarliestStartDate);
  const mppTableId = useAppSelector(selectMppTableId);
  const aiEnabled = useAppSelector(selectAiEnabled);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    dayjs.extend(utc);
  }, []);

  useEffect(() => {
    setCurrentView(table?.views ? table.views[0] : null);
  }, [table.views]);

  const onImportMpp = async (fileList: FileList) => {
    const file = fileList[0];
    const recordId = project?.id as number;
    const tableId = table.id as number;

    setRefreshRequired(false);

    await dispatch(convertMppAsync({ file, recordId, tableId }));
  };

  const getGraphUserIdFromName = async (name: string) => {
    const query = "users?$filter=displayName eq '" + encodeURIComponent(name) + "'&$select=id";
    const response = await getRequestMsGraph(query);
    const responceJson = await response.json();
    return (await responceJson.value.map((node: any) => node.id)) as string[];
  };

  const getAssignedResourcesNames = useCallback(async () => {
    if (!convertedMppRows.some(x => x.tableId === table.id)) {
      return;
    }

    if (!convertedMppRows?.length) {
      dispatch(saveConvertedMppRows(null));
      return;
    }

    const mppResources: any[] = convertedMppRows.reduce((acc: any[], row: RecordTableRow) => {
      const assignedResourceField = (row.tableRowFieldValues as TableRowValue[]).find(
        field => field.fieldId === SystemFieldType.AssignedResource
      ) as FieldValue;

      if (!assignedResourceField) {
        return acc;
      }

      const assignedResources = assignedResourceField.extendedValue?.value as any[];
      if (!assignedResources || !assignedResources.length) {
        return acc;
      }

      const result = assignedResources.filter(resource => !acc.includes(resource.name));
      if (!result || !result.length) {
        return acc;
      }

      return [...acc, ...result];
    }, [] as any[]);

    // if no resource names then save converted mpp
    if (!mppResources?.length) {
      dispatch(saveConvertedMppRows(null));
      return;
    }

    const namesToIds = await mppResources.reduce(
      async (acc: Promise<MppResourceMap[]>, resource): Promise<MppResourceMap[]> => {
        const accum = await acc;

        // Check if name is in the database and therefore a non active directory account
        const matchingResource = resources?.find(r => r.name === resource.name);

        const ignoreResourceCalendar = (matchingResource as any)?.calendar != resource.calendarId;

        if (matchingResource?.id) {
          return [
            ...accum,
            {
              name: resource.name,
              resourceId: matchingResource.id,
              ignoreResourceCalendar: ignoreResourceCalendar
            } as MppResourceMap
          ];
        } else {
          const userIds = await getGraphUserIdFromName(resource.name);
          if (userIds?.length) {
            // Found user(s) with matching name in active directory
            const resourceMap = userIds
              .map(userId => {
                const matchingResource = resources?.find(r => r.userId === userId);
                const ignoreResourceCalendar =
                  (matchingResource as any)?.calendar != resource.calendarId;

                if (matchingResource?.id) {
                  return {
                    name: resource.name,
                    resourceId: matchingResource.id,
                    ignoreResourceCalendar: ignoreResourceCalendar,
                    userId: userId
                  } as MppResourceMap;
                }
              })
              .filter(x => x != undefined) as MppResourceMap[];
            return [...accum, ...resourceMap];
          }
        }

        return accum;
      },
      Promise.resolve([] as MppResourceMap[])
    );

    if (!namesToIds?.length) {
      // if no name to id map then save converted mpp
      dispatch(saveConvertedMppRows(null));
      return;
    }

    dispatch(saveConvertedMppRows(namesToIds));
  }, [convertedMppRows, dispatch, resources]);

  useEffect(() => {
    if (!tableConfig) return;
    if (!mppEarliestStartDate) return;
    if (mppTableId !== table.id) return;

    const tableConfiguration = {
      ...tableConfig,
      startDate: mppEarliestStartDate
    };

    dispatch(updateTableConfiguration({ tableConfiguration }));
  }, [dispatch, mppEarliestStartDate, mppTableId]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (convertMppStatus === "fulfilled") {
      getAssignedResourcesNames();
    }

    if (convertMppStatus === "idle" && !initialLoad) {
      setRefreshRequired(true);
    }
  }, [convertMppStatus, getAssignedResourcesNames]);

  const hiddenColumns = useMemo(() => {
    return table.fields
      ?.filter(
        tableField =>
          !currentView?.fields?.some(viewField => viewField.fieldId === tableField.fieldId)
      )
      .map(tableField => {
        const found = fields.find(field => field.id === tableField.fieldId);
        return {
          ...found,
          name:
            (tableField.fieldId ?? 0) > SYSTEM_FIELD_ID_LIMIT ? `${found?.name}-e365` : found?.name
        };
      });
  }, [currentView, fields, table.fields]);
  const boardColumn = fields.find(field => field.id === currentView?.boardColumnFieldId);

  const commonOnChange = (changeEvent: any) => {
    if (readOnly === true || !isChangeEventValid(changeEvent, rowsInTable, table.dataType)) {
      return false;
    }
    return true;
  };

  const handleBryntumChangeEvents = (changeEvent: any, rows: RecordTableRow[]) => {
    switch (changeEvent.action) {
      case "add":
        dispatch(addNewRows({ rows }));
        break;
      case "update":
        updateAction({ rows });
        break;
      case "remove":
        changeEvent.records.forEach((record: any) => {
          deleteAction({ id: record.data.id });
        });
        break;
      default:
        break;
    }
  };

  const handleCustomChangeEvents = (changeEvent: any) => {
    switch (changeEvent.action) {
      case "add":
      case "update":
        if (changeEvent.name === "baseline" && project?.id && table?.id) {
          dispatch(
            upsertBaseline({
              baselineConfig: changeEvent.payload,
              recordId: project.id,
              tableId: table.id
            })
          );
        }
        break;
      case "remove":
        if (changeEvent.name === "baseline" && project?.id && table?.id) {
          dispatch(
            removeBaseline({
              baselineConfig: changeEvent.payload,
              recordId: project.id,
              tableId: table.id
            })
          );
        }
        break;
      default:
        break;
    }
  };

  const removeMergedPendingRows = (changeEvent: any) => {
    // Once a pending rows values have been merged into the table remove that entry from redux state
    // in order to prevent multiple updates with the same value
    const updatedRow = checkForPendingRowUpdates(changeEvent, rowsToUpdate);
    if (updatedRow && removeUpdatedRowAction) {
      removeUpdatedRowAction(getUniqueIdFromRow(updatedRow) as string);
    }
  };

  switch (table.dataType) {
    case TableDataType.List: {
      const standardColumns = orderColumns(
        table.fields?.map(tableField => {
          const found = fields.find(field => field.id === tableField.fieldId);
          return {
            ...found,
            name:
              found?.id === SystemFieldType.Name ||
              (isNotRequiredInTable(found?.requiredIn, RequiredInType.StandardTable) &&
                found?.id !== SystemFieldType.RowId)
                ? found?.name + "-e365"
                : found?.name
          } as Field;
        }) as Field[],
        currentView?.configuration?.rowOrder as RowOrder[]
      );
      const onDataChange = (changeEvent: any) => {
        // If we have not passed a project or the onChange, we are not saving the change to the backend
        if (!project) return;
        if (!onChange) return;
        const valid = commonOnChange(changeEvent);
        if (!valid) return;

        const parsed = parseTableChangeToBackend(changeEvent, table, project, fields, rowsInTable);

        if (!parsed) return;

        handleBryntumChangeEvents(changeEvent, parsed[0].tableRows);

        removeMergedPendingRows(changeEvent);

        onChange(parsed);
      };
      return (
        <UserListTable
          data={parseTableDataToFrontend(rowsInTable, fields, table.fields)}
          columns={standardColumns}
          readOnly={readOnly}
          label={table.displayName ?? table.name}
          onDataChanged={onDataChange}
          hideExportBtn={true}
          toolbarModule={
            <ViewSelector
              currentView={currentView as TableView}
              setCurrentView={setCurrentView}
              viewsList={table.views as TableView[]}
            />
          }
          boardColumns={(boardColumn?.dataStructure as any)?.choices ?? []}
          columnField={`${boardColumn?.name}-e365`}
          showBoardView={Boolean(boardColumn)}
          cardItems={getTableBoardCardItems(fields, currentView)}
          hiddenColumns={(hiddenColumns || []) as Field[]}
          required={required}
          description={table.description ? table.description : ""}
          rowsToUpdate={parseRowsToUpdateToBryntum(
            rowsInTable,
            fields,
            TableDataType.List,
            rowsToUpdate
          )}
          t={t as any}
          refreshRequired={refreshTables}
          hideEdi={hideEdi}
          onEdiClick={
            aiEnabled
              ? () => {
                  dispatch(setEdiTableId(table.id as number));
                  dispatch(setIsFlyoutOpen(true));
                  dispatch(setFlyoutId(RecordSettings.ediRowGeneration));
                }
              : undefined
          }
          tooltip={""}
          columnFilter={false}
          hideDeleteBtn={false}
          hideAddBtn={false}
          hideSearch={false}
          exportText={""}
          addRowText={""}
          deleteText={""}
          useInternalState={false}
        />
      );
    }

    case TableDataType.Schedule: {
      const taskTableColumns = orderColumns(
        table.fields
          ?.filter(
            tableField =>
              !calculatedTaskFieldIds.includes(tableField?.fieldId as number) &&
              tableField.fieldId !== SystemFieldType.ReadOnly
          )
          .map(tableField => {
            const found = fields.find(field => field.id === tableField.fieldId);
            return {
              ...found,
              name:
                isNotRequiredInTable(found?.requiredIn, RequiredInType.TaskTable) &&
                found?.id !== SystemFieldType.RowId
                  ? found?.name + "-e365"
                  : found?.name
            } as Field;
          }) as Field[],
        currentView?.configuration?.rowOrder as RowOrder[]
      );

      const { data, dependencies, assignments, totalRows } = parseTaskTableDataToFrontend(
        [...rowsInTable, ...convertedMppRows.filter(x => x.tableId === table.id)],
        fields
      );

      const updateProjectAndValue = (
        changeValue: string | number,
        field: Field | undefined,
        key: keyof BaseRecord
      ) => {
        if (changeValue != undefined && changeValue != null && field) {
          dispatch(
            updateRecordFieldValue({
              id: field.id,
              changeValue: changeValue
            })
          );
        }
        dispatch(
          updateSystemFields({
            key,
            changeValue: changeValue
          })
        );
      };

      const areDatesEqual = (date1: string | undefined | null, date2: string) => {
        return dayjs(date1)?.utc().format() === dayjs(date2)?.utc().format();
      };

      const getField = (systemFieldType: string) => {
        const field = fields.find((f: Field) => f.systemFieldType === systemFieldType);
        return field;
      };

      const updateTaskTableProperties = (
        startDate: string,
        endDate: string,
        percentDone: number | null
      ) => {
        if (!project) return;
        if (project.taskTableId === table.id) {
          if (percentDone != null) {
            percentDone = Math.round(percentDone);
            project.percentDone != percentDone &&
              updateProjectAndValue(percentDone, getField("PercentDone"), "percentDone");
          }

          startDate &&
            !areDatesEqual(project.startDate, startDate) &&
            updateProjectAndValue(
              dayjs(startDate)?.utc().format(),
              getField("StartDate"),
              "startDate"
            );

          endDate &&
            !areDatesEqual(project.endDate, endDate) &&
            updateProjectAndValue(dayjs(endDate)?.utc().format(), getField("EndDate"), "endDate");
        }
      };

      const onRollupTableChanged = (startDate: string, endDate: string, percentDone: number) => {
        updateTaskTableProperties(startDate, endDate, percentDone);
        dispatch(setHasRollupTableChanged(false));
      };

      const onProjectChange = (changeEvent: any) => {
        if (changeEvent.record instanceof ProjectModel) {
          let tableConfiguration: any = tableConfig;
          let startDateToday = false;
          const startDate =
            changeEvent.changes?.startDate?.value &&
            !dayjs(changeEvent.changes.startDate.value).isSame(dayjs(tableConfig?.startDate), "s");

          if (startDate) {
            tableConfiguration = {
              ...tableConfiguration,
              ...{
                startDate: dayjs(changeEvent.changes.startDate.value)?.utc().format()
              }
            };
          } else if (!tableConfiguration?.startDate) {
            const now = new Date();
            tableConfiguration = {
              ...tableConfiguration,
              ...{
                startDate: dayjs(
                  new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 9)
                )
                  ?.utc()
                  .format()
              }
            };
            startDateToday = true;
          }

          const endDate =
            changeEvent.changes?.endDate?.value &&
            !dayjs(changeEvent.changes.endDate.value).isSame(dayjs(tableConfig?.endDate), "s");

          if (endDate) {
            tableConfiguration = {
              ...tableConfiguration,
              ...{
                endDate: dayjs(changeEvent.changes.endDate.value)?.utc().format()
              }
            };
          }

          const saveCalendar =
            changeEvent.changes?.calendar?.value?.id &&
            changeEvent.changes.calendar.value.id != tableConfig?.taskTableCalendarId;

          if (saveCalendar) {
            tableConfiguration = {
              ...tableConfiguration,
              ...{
                taskTableCalendarId: changeEvent.changes.calendar.value.id
              }
            };
          }

          const saveDirection =
            changeEvent.changes?.direction?.value &&
            changeEvent.changes.direction.value != tableConfig?.schedulingDirection;

          if (saveDirection) {
            tableConfiguration = {
              ...tableConfiguration,
              ...{
                schedulingDirection: changeEvent.changes.direction.value
              }
            };
          }

          const saveMode =
            changeEvent.changes?.schedulingMode &&
            changeEvent.changes.schedulingMode !== tableConfig?.schedulingMode;

          if (saveMode) {
            tableConfiguration = {
              ...tableConfiguration,
              ...{
                schedulingMode: changeEvent.changes.schedulingMode
              }
            };
          }

          if (saveCalendar || saveDirection || saveMode || startDate || endDate || startDateToday) {
            if (!tableConfiguration.tableId) tableConfiguration.tableId = table.id;

            dispatch(
              updateTableConfiguration({
                tableConfiguration
              })
            );
          }
        }
      };

      const isLegitimateChange = (changeEvent: any) => {
        const isLegitimate = Object.keys(changeEvent.changes).some((changeType: any) => {
          const calculatedFields = [
            "lateStartDate",
            "lateEndDate",
            "earlyStartDate",
            "earlyEndDate",
            "totalSlack",
            "direction"
          ];

          if (calculatedFields.includes(changeType)) return false;

          const change = changeEvent.changes[changeType];
          if (!change) return false;

          const pair = getTaskTableSystemFieldsBryntumMap().find(pair => {
            return pair.bryntum === changeType;
          });

          const field = pair
            ? fields.find(field => {
                return field.systemFieldType === SystemFieldType[pair.edison];
              })
            : undefined;

          const type = field?.dataType;
          if (type === "Number") return hasNumberChanged(change);
          if (type === "DateTime") return hasDateChanged(change, "second");
          if (type === "Date") return hasDateChanged(change, "day");

          return change.value != change.oldValue;
        });

        return isLegitimate;
      };

      const hasNumberChanged = (change: any) => {
        if (!change.value && !change.oldValue) return false;
        if (change.value && !change.oldValue) return true;
        if (!change.value && change.oldValue) return true;
        return (
          change.value &&
          !isNaN(change.value) &&
          change.oldValue &&
          !isNaN(change.oldValue) &&
          change.value.toFixed(3) !== change.oldValue.toFixed(3)
        );
      };

      const hasDateChanged = (change: any, granularity: OpUnitType) => {
        return (
          !dayjs(change.value).isSame(dayjs(change.oldValue), granularity) &&
          change.value != change.oldValue
        );
      };

      const onTaskChange = (changeEvent: any) => {
        if (
          changeEvent.store instanceof TaskStore &&
          changeEvent.type === "datachange" &&
          changeEvent.action === "update" &&
          changeEvent.changes
        ) {
          if (!isLegitimateChange(changeEvent)) return;
        }

        if (
          changeEvent.store instanceof TaskStore &&
          changeEvent.type === "datachange" &&
          changeEvent.action != "dataset" &&
          changeEvent.store.records?.length
        ) {
          updateTaskTableProperties(
            changeEvent.project.startDate,
            changeEvent.project.endDate,
            changeEvent.project.percentDone
          );
        }

        // If we have not passed a project or the onChange, we are not saving the change to the backend
        if (!project) return;
        if (!onChange) return;

        if (changeEvent.type === "custom") {
          handleCustomChangeEvents(changeEvent);
          return;
        }

        const valid = commonOnChange(changeEvent);
        if (!valid) return;

        removeMergedPendingRows(changeEvent);
        const parsed = parseTaskTableChangeToBackend(
          changeEvent,
          table,
          project,
          fields,
          rowsInTable,
          taskTableColumns
        );

        if (!parsed) return;

        handleBryntumChangeEvents(changeEvent, parsed[0].tableRows);
        onChange(parsed);
      };

      const sortedCalendars = [...(calendars || [])].sort((a, b) =>
        (a?.displayName || "").localeCompare(b?.displayName || "")
      );
      return (
        <UserScheduleTable
          t={t}
          readOnly={readOnly}
          timeZone={project?.timeZone ? project?.timeZone : "UTC"}
          columns={taskTableColumns}
          label={table.displayName ?? table.name}
          boardColumns={(boardColumn?.dataStructure as any)?.choices ?? []}
          columnField={`${boardColumn?.name}-e365`}
          showBoardView={Boolean(boardColumn)}
          data={data}
          totalRows={totalRows}
          dependenciesData={dependencies as any}
          resources={resources}
          calendars={sortedCalendars}
          assignments={assignments as any}
          onChange={onTaskChange}
          onImportMpp={onImportMpp as any}
          convertingMpp={convertMppStatus === "loading"}
          mppResponseType={mppResponseType}
          refreshRequired={refreshRequired || !!refreshTables}
          onProjectChange={onProjectChange}
          hideEdi={hideEdi}
          onEdiClick={
            aiEnabled
              ? () => {
                  dispatch(setEdiTableId(table.id as number));
                  dispatch(setIsFlyoutOpen(true));
                  dispatch(setFlyoutId(RecordSettings.ediRowGeneration));
                }
              : undefined
          }
          newData={
            ediTableId === table.id
              ? (ediRows as { add?: TaskModel[]; remove?: TaskModel[] })
              : ({} as any)
          }
          showCriticalPaths={false}
          showTimeline={false}
          rowsToUpdate={parseRowsToUpdateToBryntum(
            rowsInTable,
            fields,
            TableDataType.Schedule,
            rowsToUpdate
          )}
          toolbarModule={
            <ViewSelector
              currentView={currentView as TableView}
              setCurrentView={setCurrentView}
              viewsList={table.views as TableView[]}
            />
          }
          hiddenColumns={(hiddenColumns || []) as Field[]}
          currentViewColumnOrder={
            currentView?.configuration?.rowOrder
              ? (currentView?.configuration?.rowOrder as any[])
              : undefined
          }
          tableConfig={tableConfig}
          onRollupTableChanged={onRollupTableChanged}
          rollupTableChanged={hasRollupTableChanged}
          required={required}
          description={table.description ? table.description : ""}
          noDatesFromConfig={noDatesFromConfig}
          hideTableConfigModal={hideTableConfigModal}
          onError={(error: any) =>
            appInsights.trackException(
              { error: error, severityLevel: 3 },
              {
                name: "UserScheduleTable load failure",
                properties: { tableId: table.id, projectId: project?.id }
              }
            )
          }
          addCardText={""}
          addTaskText={""}
          boardViewText={""}
          editCardText={""}
          peopleList={[]}
          redoText={""}
          removeCardText={""}
          taskViewText={""}
          undoText={""}
          viewCardText={""}
          zoomInText={""}
          zoomOutText={""}
          zoomToFitText={""}
          useInternalState={false}
        />
      );
    }

    case TableDataType.Periodic: {
      const periodicColumns = orderColumns(
        table.fields?.map(tableField => {
          const found = fields.find(field => field.id === tableField.fieldId);
          return {
            ...found,
            name:
              found?.id === SystemFieldType.Name ||
              (isNotRequiredInTable(found?.requiredIn, RequiredInType.PeriodicTable) &&
                found?.id !== SystemFieldType.RowId)
                ? found?.name + "-e365"
                : found?.name
          } as Field;
        }) as Field[],
        currentView?.configuration?.rowOrder as RowOrder[]
      );

      const onPeriodicChange = (changeEvent: any) => {
        // If we have not passed a project or the onChange, we are not saving the change to the backend
        if (!project) return;
        if (!onChange) return;
        const valid = commonOnChange(changeEvent);
        if (!valid) return;

        const parsed = parsePeriodicTableChangeToBackend(
          changeEvent,
          table,
          project,
          fields,
          rowsInTable
        );

        if (!parsed) return;
        removeMergedPendingRows(changeEvent);
        handleBryntumChangeEvents(changeEvent, parsed[0].tableRows);
        onChange(parsed);
      };
      const parsedRows = parsePeriodicTableDataToFrontend(
        rowsInTable,
        fields,
        table.periodicUnits === TablePeriodicUnits.Years
      );
      const today = new Date();
      return (
        <UserPeriodicTable
          detailFields={periodicColumns}
          readOnly={readOnly}
          label={table.displayName ?? table.name}
          rows={parsedRows as PeriodicRow[]}
          periodicUnit={getPeriodicUnits(table.periodicUnits as string)}
          useInternalState={false}
          grandTotals={periodicGrandTotals || {}}
          rowsToUpdate={parseRowsToUpdateToBryntum(
            rowsInTable,
            fields,
            TableDataType.Periodic,
            rowsToUpdate
          )}
          toolbarModule={
            <ViewSelector
              currentView={currentView as TableView}
              setCurrentView={setCurrentView}
              viewsList={table.views as TableView[]}
            />
          }
          // boardColumns={(boardColumn?.dataStructure as any)?.choices ?? []}
          // columnField={`${boardColumn?.name}-e365`}
          // showBoardView={Boolean(boardColumn)}
          onDataChanged={onPeriodicChange}
          hideEdi={hideEdi}
          onEdiClick={
            aiEnabled
              ? () => {
                  dispatch(setEdiTableId(table.id as number));
                  dispatch(setIsFlyoutOpen(true));
                  dispatch(setFlyoutId(RecordSettings.ediRowGeneration));
                }
              : undefined
          }
          hiddenColumns={(hiddenColumns || []) as Field[]}
          required={required}
          periodicStartDate={new Date(today.getFullYear(), 0)}
          description={table.description ? table.description : ""}
          t={t}
          refreshRequired={refreshTables}
          exportText={""}
          addRowText={""}
          deleteText={""}
          onPeoplePickerOnChange={undefined}
        />
      );
    }
    default:
      return <div> table not found</div>;
  }
};
export default TableMapper;

const getPeriodicUnits = (unit: string | undefined) => {
  switch (unit) {
    case "Weeks":
      return TablePeriodicUnits.Weeks;
    case "Months":
      return TablePeriodicUnits.Months;
    case "Quarters":
      return TablePeriodicUnits.Quarters;
    case "Years":
      return TablePeriodicUnits.Years;
    default:
      return TablePeriodicUnits.Weeks;
  }
};
interface ViewSelectorProps {
  currentView: TableView;
  setCurrentView: (view: TableView) => void;
  viewsList: TableView[];
}
const ViewSelector: FC<ViewSelectorProps> = ({ currentView, setCurrentView, viewsList }) => {
  return (
    <Select
      disabled={false}
      size="small"
      sx={theme => ({
        ".MuiSvgIcon-root ": {
          color: theme.palette.primary.main
        },
        ".MuiOutlinedInput-notchedOutline ": {
          border: "0px"
        },
        border: "1px solid",
        borderRadius: "10px",
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        height: "36px"
      })}
      value={currentView.name}
      onChange={e => {
        setCurrentView(viewsList.find(view => view.name === e.target.value) as TableView);
      }}
    >
      {viewsList.map((view, index) => (
        <MenuItem key={index} value={view.name}>
          {view.displayName}
        </MenuItem>
      ))}
    </Select>
  );
};
export const calculatedTaskFieldIds: number[] = [SystemFieldType.Critical];
