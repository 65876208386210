import React from "react";
import { Field, FieldDataType, TableDataType } from "enada-common";
import { getBryntumColumnByFieldName } from "../../tableutils/taskTableUtils";
import { generateBaseBryntumColumn } from "../../tableutils/generateBaseBryntumColumn";

const generateTaskTableColumns = (columns: Field[]): any => {
  const cols: any[] = [];
  columns.forEach(column => {
    if (column.name === "e365-Baselines") return; // currently Bryntum don't support baselines but have plans to in a later release
    if (column.name.endsWith("-e365")) {
      cols.push({
        ...generateBaseBryntumColumn(column, [], undefined, TableDataType.Schedule),
        cls: "gridHeader",
        htmlEncodeHeaderText: false
      });
    } else {
      let baseColumn: any = {
        text: column.displayName,
        id: column.id
      };

      const ColumnMappedToBryntum = getBryntumColumnByFieldName(column.name);
      if (ColumnMappedToBryntum) {
        baseColumn = {
          ...baseColumn,
          ...ColumnMappedToBryntum.definition
        };

        // ootb Bryntum fields wth "special" column types should not have "field" set in column def.
        // This breaks base functionality of column type
        if (ColumnMappedToBryntum.setField) {
          baseColumn.field = ColumnMappedToBryntum.bryntumName ?? column.name;
        }
      }
      cols.push(baseColumn);
    }
  });
  return cols;
};

export default generateTaskTableColumns;
