import { NotificationLevel, ResourceType, RollupTableRowModel, toCamelCase } from "enada-common";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { resourceRollupTableColumnsWithType } from "../../../config/rollupTableColumns";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { RollupTable } from "enada-components";
import {
  clearError,
  deleteResourcesAsync,
  getResourcesAsync,
  recycleResourcesAsync,
  restoreResourcesAsync,
  selectError,
  selectResourcesRollUp
} from "../../../store/slices/resourcesSlice";
import "./resources.scss";
import { batchGetUsersByIdAsync, selectPeople } from "../../../store/slices/entitiesSlice";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import { useGetCalendarsQuery } from "services/api";

const Resources: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);

  const { data: calendars = [] } = useGetCalendarsQuery();

  const rollupRows = useAppSelector(selectResourcesRollUp);
  const entities = useAppSelector(selectPeople);
  const error = useAppSelector(selectError);

  useEffect(() => {
    dispatch(getResourcesAsync());
  }, [dispatch]);

  if (error?.detail) {
    const errorArr = JSON.parse(error.detail);
    const errorMessage = errorArr.reduce((acc: string, curr: any) => {
      let formatted = "";
      if (error.title === "ResourceAssignedAsManager") {
        const resources = curr.ManagedResources.map(
          (resource: any) =>
            resource.ResourceName ??
            entities[resource.ResourceEntityId]?.displayName ??
            resource.ResourceEntityId
        ).join(", ");
        formatted = `${t("resourceIsResourceManagerError", {
          resource: entities[curr.ResourceEntityId]?.displayName,
          resources: resources
        })}\n`;
      } else if (error.title === "ResourceAssignedToTask") {
        const recordNames = curr.Records.map((record: any) => record.RecordName).join(", ");
        formatted = `${t("resourceAssignedToTaskError", {
          resource: entities[curr.ResourceEntityId]?.displayName,
          records: recordNames
        })}\n`;
      }

      return acc + formatted + "\n";
    }, "");
    dispatch(
      setCurrentNotification({
        title: error.title,
        message: errorMessage,
        level: NotificationLevel.Error
      })
    );

    dispatch(clearError());
  }

  const navigate = useNavigate();
  const deleteRows = (ids: string[]) => {
    dispatch(deleteResourcesAsync(ids.map((id: string) => parseInt(id))));
  };

  const recycleRows = (ids: string[]) => {
    dispatch(recycleResourcesAsync(ids.map((id: string) => parseInt(id))));
  };

  const restoreRows = (ids: string[]) => {
    dispatch(restoreResourcesAsync(ids.map((id: string) => parseInt(id))));
  };

  useEffect(() => {
    const newIds = rollupRows
      .filter(row => row.type === ResourceType.AAD && entities[row?.userId as string] === undefined)
      .map(row => row.userId);
    dispatch(batchGetUsersByIdAsync(newIds as string[]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rollupRows]);

  return (
    <RollupTable
      fullScreen={false}
      setFullScreen={() => {
        /*nothing*/
      }}
      order="desc"
      orderBy="modified"
      renderEmptyTable={true}
      editAction={(id: string) => {
        navigate(`editresource`, {
          state: {
            resource: rollupRows.find(item => item.id?.toString() === id),
            title: "resources"
          }
        });
      }}
      addAction={() => {
        navigate("newresource", {
          state: {
            title: "resources"
          }
        });
      }}
      columns={resourceRollupTableColumnsWithType(t)}
      rows={
        rollupRows
          .filter(resource => !resource.isDeleted)
          .map((resource: any) => ({
            ...resource,
            isActive: resource.isActive ? "active" : "inactive",
            calendar: calendars.find(cal => cal.id === resource.calendarId)?.displayName,
            type: t(toCamelCase(resource.type)),
            displayName:
              resource.type === ResourceType.AAD
                ? entities[resource.userId]?.displayName
                : resource.displayName
          })) as unknown as RollupTableRowModel[]
      }
      deletedRows={
        rollupRows.filter(resource => resource.isDeleted) as unknown as RollupTableRowModel[]
      }
      t={t}
      deleteAction={(ids: string[]) => deleteRows(ids)}
      recycleAction={(ids: string[]) => recycleRows(ids)}
      restoreAction={(ids: string[]) => restoreRows(ids)}
    />
  );
};

export default Resources;
