import React, { FC, useEffect, useState } from "react";
import { BaseUserFieldProps, fieldConfiguration, FieldDataType, useDebounce } from "enada-common";
import EdisonFieldIcon from "../../edison/fieldicon/EdisonFieldIcon";
import TextField from "@mui/material/TextField";

export interface UserTextFieldProps extends BaseUserFieldProps {
  isIconOn: boolean;
  minimumLength?: number;
  maximumLength?: number;
  setReadOnly?: (value: boolean) => void;
}

const UserTextField: FC<UserTextFieldProps> = ({
  label,
  value,
  readOnly,
  isInTable,
  onChange,
  error,
  isIconOn,
  minimumLength,
  maximumLength,
  setReadOnly,
  autoFocus,
  required
}) => {
  const getIsValid = (value: string) => {
    if (minimumLength && value) {
      return value.length >= minimumLength;
    }
    return true;
  };

  const [internalState, setInternalState] = useState<string>(value || "");
  const [isValid, setIsValid] = useState<boolean>(getIsValid(value));

  useEffect(() => {
    setInternalState(value);
  }, [value]);

  const handleChange = (value: string) => {
    setInternalState(value);
    setIsValid(getIsValid(value));
  };

  useDebounce(
    () => {
      if (!isValid) return;
      if (isInTable) return;
      if (!onChange) return;
      onChange(internalState, isValid);
    },
    1000,
    [internalState]
  );

  return (
    <div className="user-text-field-root">
      {!readOnly ? (
        <TextField
          required={required}
          variant="standard"
          type="text"
          multiline={isInTable}
          label={!isInTable && label}
          fullWidth
          autoFocus={autoFocus}
          onFocus={
            isInTable
              ? event => {
                  event.target.select();
                }
              : undefined
          }
          value={internalState ?? ""}
          onChange={e => {
            handleChange(e.target.value);
          }}
          inputProps={{
            minLength: minimumLength,
            maxLength: maximumLength
          }}
          helperText=""
          size="small"
          error={error || !isValid}
          onBlur={() => {
            if (!isValid) return;
            if (!setReadOnly) return;
            setReadOnly(true);
            if (!onChange) return;
            if (!isInTable) return;
            onChange(internalState);
          }}
        />
      ) : (
        <EdisonFieldIcon
          title={label}
          subtitle={internalState}
          additionalStyles={{
            wordBreak: "break-word"
          }}
          icon={fieldConfiguration(FieldDataType.TextBox).icon}
          color={fieldConfiguration(FieldDataType.TextBox).color}
          isIconOn={isIconOn}
          isTableModeOn={!!isInTable}
          required={required}
          error={required && internalState === ""}
        />
      )}
    </div>
  );
};

export default UserTextField;
export { UserTextField };
