import { Field, FieldDataType, TableDataType } from "enada-common";
import TypeToComponentMapper from "./useTypeToComponentMapper";

const getFilterByType = (props: any, field: Field) => {
  const value = props.record[field.name];
  let filteredValue;
  switch (field.dataType) {
    case FieldDataType.MultiChoice:
    case FieldDataType.MultiLevelChoice:
      filteredValue = value?.some((choice: any) =>
        (choice.label as string).toLowerCase().includes((props.value as string).toLowerCase())
      );
      return filteredValue;
    case FieldDataType.Choice:
      filteredValue =
        value &&
        value.label &&
        (value.label as string).toLowerCase().includes((props.value as string).toLowerCase());
      return filteredValue;
    case FieldDataType.Url:
      filteredValue =
        value && value.title
          ? (value.title as string)
          : (value.url as string).toLowerCase().includes((props.value as string).toLowerCase());
      return filteredValue;
  }
};

export const generateBaseBryntumColumn = (
  field: Field,
  columns: Field[],
  additionalProperties?: any,
  source?: TableDataType,
  labelToSearchFor?: string
) => {
  let editable = true;
  if (
    (field as Field).systemFieldType &&
    ["Modified", "ModifiedBy", "CreatedBy", "Created", "UniqueId", "RowId"].includes(
      field.systemFieldType as string
    )
  ) {
    editable = false;
  }

  return {
    id: field.id,
    field: field.name,
    text: field.displayName,
    instantUpdate: false,
    autoHeight: false,
    width: getColumnWidthByFieldType(field.dataType),
    editor: false,
    cellCls: "user-table-cell",
    align: field.dataType === FieldDataType.Number ? "right" : "left",
    renderer: (args: any) => {
      const defaultValue = args.value ? args.value : { value: null, readOnly: true };

      if (args.column.data.dynamicHeight) {
        //When we have set autoheight then we want to measure the heights of the user components to set the height of the cell
        const component = getComponentToMeasure(args.cellElement, field.dataType);
        if (component && component.offsetHeight > 45) {
          args.size.height = component.offsetHeight;
        }
      } else {
        if (args.size.height !== 45) {
          args.size.height = 45;
        }
      }
      return TypeToComponentMapper(
        field,
        !editable ? defaultValue.readOnly ?? true : false,
        true,
        true,
        columns,
        args.record,
        args.value,
        (changeValue: any, isValid?: boolean) => {
          if (isValid || isValid === undefined) {
            args.record.set(field.name, changeValue);
          }
        },
        !editable,
        source,
        labelToSearchFor
      );
    },
    filterable: [
      FieldDataType.MultiChoice,
      FieldDataType.MultiLevelChoice,
      FieldDataType.Choice,
      FieldDataType.Url
    ].includes(field.dataType)
      ? (props: any) => getFilterByType(props as any, field)
      : [
            FieldDataType.People,
            FieldDataType.MultiPeople,
            FieldDataType.Calculated,
            FieldDataType.RichText
          ].includes(field.dataType)
        ? false
        : true,
    ...additionalProperties
  };
};

const getColumnWidthByFieldType = (fieldType: FieldDataType): number => {
  switch (fieldType) {
    case FieldDataType.RichText:
    case FieldDataType.People:
    case FieldDataType.Choice:
    case FieldDataType.MultiChoice:
    case FieldDataType.MultiLevelChoice:
    case FieldDataType.MultiMultiLevelChoice:
    case FieldDataType.DateTime:
      return 250;
    default:
      return 150;
  }
};

const getComponentToMeasure = (
  cellElement: any,
  type: FieldDataType
): HTMLElement | null | undefined => {
  //Foreach usercomponent that supports dynamic height get measurable component by its class
  const classToSearchFor = getClassToSearchFor(type);

  const result = cellElement?.querySelector(classToSearchFor) as HTMLElement;

  return classToSearchFor === "" ? undefined : result;
};

export const getClassToSearchFor = (type?: FieldDataType) => {
  let classToSearchFor = "";

  switch (type) {
    case FieldDataType.MultiLevelChoice:
    case FieldDataType.MultiChoice:
      classToSearchFor = ".multichoice-in-table-tags";
      break;

    case FieldDataType.RichText:
      classToSearchFor = ".slate-editor";
      break;
    case FieldDataType.TextBox:
      classToSearchFor = ".user-text-field-root";
      break;
    case FieldDataType.Email:
      classToSearchFor = ".user-email-field-root";
      break;
    default:
      break;
  }
  return classToSearchFor;
};
