import { reducerStatus } from "enada-common";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getRegion } from "../../services/APIService";

export interface ConfigState {
  region: string;
  status: reducerStatus;
}

const initialState: ConfigState = {
  region: "",
  status: "idle"
};

export const getRegionAsync: any = createAsyncThunk(
  "user/getRegion",
  async (_, { rejectWithValue }) => {
    const response = await getRegion();
    if (!(response.status as number).toString().startsWith("2")) {
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRegionAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getRegionAsync.fulfilled, (state, action) => {
        state.region = action.payload;
        state.status = "idle";
      })
      .addCase(getRegionAsync.rejected, (state: any) => {
        state.status = "failed";
      });
  }
});

const inputSelectConfig = (state: RootState) => state.config;

export const selectRegion = createSelector([inputSelectConfig], config => config.region);

export const selectConfigStatus = createSelector([inputSelectConfig], config => config.status);

export default configSlice.reducer;
