import { ApprovalState, LicenseType, WorkItemTask } from "enada-common";
import { Box, Button, Chip, TableRow, Theme, darken, lighten, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EdisonTypography } from "enada-components";
import HasMinimumLicense from "../../../hasMinimumLicense/HasMinimumLicense";
import { BorderedTableCell } from "../../approvalstable/ApprovalsTable";
import "./taskrow.scss";
import TasksRowDialog from "./tasksrowdialog/TasksRowDialog";
import { StringHelper } from "@bryntum/core-thin";
import { useGetMyWorkRows } from "../../../../utils/hooks/useGetMyWorkRows";

export interface TasksRowProps {
  task: WorkItemTask;
}
const TasksRow: FC<TasksRowProps> = ({ task }) => {
  const { t } = useTranslation(["common"]);
  const theme = useTheme();
  const { onGetItemFields, foundRecordTableRows, table, onCloseDialog } = useGetMyWorkRows(task);

  return (
    <TableRow className="tasks-row-root" hover>
      <BorderedTableCell className="cell">
        <Box onClick={() => onGetItemFields()}>
          <EdisonTypography
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            variant="data2"
            title={StringHelper.decodeHtml(task.itemName ?? "")}
          />
        </Box>
      </BorderedTableCell>
      <BorderedTableCell className="cell description">
        <EdisonTypography variant="data2" title={task.description ?? ""} />
      </BorderedTableCell>
      <BorderedTableCell className="cell card">
        <Link
          className="tasks-link"
          to={`../${task.recordType?.toLocaleLowerCase()}/${task.recordId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {task.recordName ?? task.recordId}
        </Link>
      </BorderedTableCell>
      <BorderedTableCell className="cell end-date">
        <EdisonTypography variant="data2" title={dayjs(task.dueDate).format("DD/MM/YYYY")} />
      </BorderedTableCell>
      <BorderedTableCell className="cell percentage-complete">
        <EdisonTypography variant="data2" title={task.percentComplete?.toString() ?? "0"} />
      </BorderedTableCell>
      <BorderedTableCell className="cell effort">
        <EdisonTypography variant="data2" title={task.effort?.toString() ?? ""} />
      </BorderedTableCell>
      <BorderedTableCell className="cell effort">
        {getStatusChip(theme, task.approvalState)}
      </BorderedTableCell>
      <HasMinimumLicense minimumUserLicenseType={LicenseType.Lite}>
        <>
          <BorderedTableCell className="cell action">
            <Button onClick={onGetItemFields}>{t("submit")}</Button>
          </BorderedTableCell>
          <TasksRowDialog
            identifier={task?.itemName ?? task?.itemId?.toString() ?? ""}
            onClose={onCloseDialog}
            approvalState={task?.approvalState}
            recordTableRow={foundRecordTableRows}
            table={table}
            recordType={task.recordType}
            recordId={task.recordId}
            baseRowId={task.itemId}
          />
        </>
      </HasMinimumLicense>
    </TableRow>
  );
};

export const getStatusChip = (theme: Theme, state?: ApprovalState) => {
  let background = "";
  let color = "";
  switch (state) {
    case ApprovalState.Approved:
      background = lighten(theme.palette.success.main, 0.8);
      color = darken(theme.palette.success.main, 0.6);
      break;
    case ApprovalState.Rejected:
      background = lighten(theme.palette.error.main, 0.8);
      color = darken(theme.palette.error.main, 0.6);
      break;
    case ApprovalState.Submitted:
      background = lighten(theme.palette.info.main, 0.8);
      color = darken(theme.palette.info.main, 0.6);
      break;
    case ApprovalState.Saved:
      background = lighten(theme.palette.warning.main, 0.8);
      color = darken(theme.palette.warning.main, 0.6);
      break;

    default:
      background = lighten(theme.palette.action.selected, 0.08);
      color = theme.palette.text.primary;
      break;
  }
  return (
    <Chip
      label={state ?? "New"}
      sx={{
        backgroundColor: background,
        color: color
      }}
    />
  );
};

export default TasksRow;
