import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  recordTypeConfiguration,
  NotificationLevel,
  RollupTableRowModel,
  Category,
  ApiError
} from "enada-common";
import { rollupTableColumnsWithType } from "../../../config/rollupTableColumns";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RollupTable } from "enada-components";
import { resetLiveCard } from "../../../store/slices/cardDesignerSlice";
import { setCurrentNotification } from "../../../store/slices/notificationSlice";
import {
  useDeleteCardsMutation,
  useGetCardsQuery,
  useRecycleCardsMutation,
  useRestoreCardsMutation
} from "../../../services/api";
import { selectRollupRowsFromData } from "../../../utils/rtkQuery";

const selectCardsRollupRows = selectRollupRowsFromData(data => {
  return data?.map(card => ({
    id: card.id as number,
    displayName: card.displayName,
    modified: card.modified,
    modifiedBy: card.modifiedBy,
    type: card.cardType,
    data: card,

    category: card.category,
    no_delete_e365: card.category === Category.System || card.category === Category.Default
  }));
});
const Cards: FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { cardsRollupRows = [] } = useGetCardsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      cardsRollupRows: selectCardsRollupRows(result)
    })
  });
  const [deleteCards] = useDeleteCardsMutation();
  const [recycleCards] = useRecycleCardsMutation();
  const [restoreCards] = useRestoreCardsMutation();
  const deleteRows = async (ids: string[]) => {
    try {
      const response = await deleteCards(ids.map((id: string) => parseInt(id)));
      if (response) {
        dispatch(
          setCurrentNotification({
            title: "cardDeleted",
            message: "",
            level: NotificationLevel.Success
          })
        );
      }
    } catch (e: any) {
      const error = e as ApiError;
      if (error?.detail) {
        const errorArr = JSON.parse(error.detail);
        const errorMessage = errorArr.reduce((acc: string, errorCard: any) => {
          const workflowNames = errorCard.Workflows.map(
            (workflow: any) => workflow.WorkflowName
          ).join(", ");
          const formatted = `${t("cardInUseError", {
            cards: errorCard.CardName,
            workflows: workflowNames
          })}\n`;
          return acc + formatted + "\n";
        }, "");
        dispatch(
          setCurrentNotification({
            title: error.title,
            message: errorMessage,
            level: NotificationLevel.Error
          })
        );
      }
    }
  };

  const recycleRows = async (ids: string[]) => {
    try {
      const response = await recycleCards(ids.map((id: string) => parseInt(id)));
      if (response) {
        dispatch(
          setCurrentNotification({
            title: "cardRecycled",
            message: "",
            level: NotificationLevel.Success
          })
        );
      }
    } catch {
      dispatch(
        setCurrentNotification({
          title: "cardRecycledError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  const restoreRows = async (ids: string[]) => {
    try {
      const response = await restoreCards(ids.map((id: string) => parseInt(id)));
      if (response) {
        dispatch(
          setCurrentNotification({
            title: "cardRestored",
            message: "",
            level: NotificationLevel.Success
          })
        );
      }
    } catch {
      dispatch(
        setCurrentNotification({
          title: "cardRestoredError",
          message: "",
          level: NotificationLevel.Error
        })
      );
    }
  };

  return (
    <RollupTable
      fullScreen={false}
      setFullScreen={() => {
        /*nothing*/
      }}
      order="desc"
      orderBy="modified"
      renderEmptyTable={true}
      editAction={(id: string) => {
        navigate("editcard", {
          state: {
            item: cardsRollupRows.find((card: any) => card?.data.id?.toString() === id),
            title: "cards"
          }
        });
      }}
      addAction={() => {
        dispatch(resetLiveCard());
        navigate("newcard", {
          state: {
            title: "cards"
          }
        });
      }}
      columns={rollupTableColumnsWithType(t, value => recordTypeConfiguration(value.type).color)}
      rows={
        cardsRollupRows.filter(
          (card: any) => !card.data.isDeleted
        ) as unknown as RollupTableRowModel[]
      }
      deletedRows={
        cardsRollupRows.filter(
          (card: any) => card.data.isDeleted
        ) as unknown as RollupTableRowModel[]
      }
      t={t}
      deleteAction={(ids: string[]) => deleteRows(ids)}
      recycleAction={(ids: string[]) => recycleRows(ids)}
      restoreAction={(ids: string[]) => restoreRows(ids)}
    />
  );
};

export default Cards;
