import { Workflow } from "enada-common";
import { apiConfig } from "../../config/authConfig";
import { baseAPI } from "../baseAPI";
import { BaseWorkflowStage } from "store/slices/workflowSlice";

export const workflowsAPI = baseAPI.injectEndpoints({
  endpoints: builder => ({
    getWorkflows: builder.query<Workflow[], void>({
      query: () => `${apiConfig.contentHubWorkflowEndpoint}`,
      transformResponse: (response: { data: Workflow[] }) => response.data,
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: "Workflows" as const, id })), "Workflows"]
          : ["Workflows"]
    }),

    getWorkflowStages: builder.query<BaseWorkflowStage[], void>({
      query: () => `${apiConfig.contentHubApi}/stages`,
      transformResponse: (response: { data: BaseWorkflowStage[] }) => response.data,
      providesTags: ["Workflows"]
    }),

    getWorkflow: builder.query<Workflow, number>({
      query: id => `${apiConfig.contentHubWorkflowEndpoint}/${id}`,
      transformResponse: (response: { data: Workflow }) => response.data,
      providesTags: (result, error, id) => [{ type: "Workflows", id }]
    }),

    createWorkflow: builder.mutation<Workflow, Partial<Workflow>>({
      query: body => ({
        url: apiConfig.contentHubWorkflowEndpoint,
        method: "POST",
        body
      }),
      transformResponse: (response: { data: Workflow }) => response.data,
      invalidatesTags: ["Workflows"]
    }),

    updateWorkflow: builder.mutation<Workflow, Partial<Workflow>>({
      query: body => ({
        url: apiConfig.contentHubWorkflowEndpoint,
        method: "PUT",
        body
      }),
      transformResponse: (response: { data: Workflow }) => response.data,
      invalidatesTags: (result, error, body) => [{ type: "Workflows", id: body.id }]
    }),

    deleteWorkflows: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubWorkflowEndpoint}`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Workflows", id }))
    }),

    restoreWorkflows: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubWorkflowEndpoint}/restore`,
        method: "POST",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Workflows", id }))
    }),

    recycleWorkflows: builder.mutation<number, number[]>({
      query: ids => ({
        url: `${apiConfig.contentHubWorkflowEndpoint}/recycle`,
        method: "DELETE",
        body: ids
      }),
      invalidatesTags: (result, error, ids) => ids.map(id => ({ type: "Workflows", id }))
    })
  })
});
