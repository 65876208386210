import {
  ApiError,
  Category,
  Form,
  NotificationLevel,
  RecordType,
  sanitiseInternalFieldName
} from "enada-common";
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { FC, useCallback, useEffect, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Features from "../../../components/features/Features";
import LivePreview from "../../../components/formdesigner/LivePreview";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectAllFields } from "../../../store/slices/fieldsSlice";
import {
  getLivePreviewTableAsync,
  resetLiveForm,
  selectFormData,
  selectFormDescription,
  selectFormInternalName,
  selectFormName,
  selectFormScope,
  selectFormsDisabledItemList,
  setFormData,
  setFormDescription,
  setFormName,
  setFormScope,
  setInternalFormName
} from "../../../store/slices/formDesignerSlice";
import { getOrderedColumn, parseFormDataToFrontend } from "../../../store/slices/parseFormData";

import { ConfigLayout } from "enada-components";
import "./formdesigner.scss";
import { useHasModule } from "../../../utils/hooks/useHasModule";
import { useCreateFormMutation, useGetFormQuery, useUpdateFormMutation } from "services/api";
import { useGetFieldsQuery, useGetTablesQuery } from "services/api";
import { setCurrentNotification } from "store/slices/notificationSlice";

const FormDesigner: FC = () => {
  const { t } = useTranslation(["common"]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const isEdit = Boolean(state?.id);
  const { data: currentForm, isLoading: formIsLoading } = useGetFormQuery(state.id, {
    skip: !isEdit
  });

  const { fields = [], isLoading: fieldsIsLoading } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });
  const [createForm] = useCreateFormMutation();
  const [updateForm] = useUpdateFormMutation();

  const { isModuleEnabled } = useHasModule();

  const { data: tables = [], isLoading: tablesIsLoading } = useGetTablesQuery();

  const formData = useAppSelector(selectFormData);
  const formName = useAppSelector(selectFormName);
  const formInternalName = useAppSelector(selectFormInternalName);
  const formDescription = useAppSelector(selectFormDescription);
  const formScope = useAppSelector(selectFormScope);

  const mapFormResult = useCallback(
    (): Form | Partial<Form> => ({
      name: formInternalName,
      displayName: formName,
      dataType: formScope as RecordType,
      description: formDescription,
      isDeleted: false,
      containers: formData.map((tab, tabIndex) => {
        return {
          displayName: tab.title,
          type: "tab",
          id: tab.dataId,
          order: tabIndex,
          zones: tab.children?.map((row: any, rowIndex: number) => {
            return {
              displayName: row.title,
              id: row.dataId,
              order: rowIndex,
              columns: row.children.map((column: any, columnIndex: number) =>
                getOrderedColumn(column, columnIndex)
              )
            };
          })
        };
      })
    }),
    [formData, formDescription, formInternalName, formName, formScope]
  );

  useEffect(() => {
    if (currentForm && !formIsLoading && !fieldsIsLoading && !tablesIsLoading && isEdit) {
      const result = parseFormDataToFrontend(currentForm, fields, tables);
      dispatch(setFormData(result.formData));
      dispatch(setFormName(currentForm.displayName || ""));
      dispatch(setInternalFormName(currentForm.name || ""));
      dispatch(setFormDescription(currentForm.description || ""));
      dispatch(setFormScope(currentForm.dataType || RecordType.Projects));
      // dispatch(setTableIds(result.tableIds ?? [])); commented out while we wait for the BE to optionally return views[] with all tables
      for (const id of result.tableIds) {
        dispatch(getLivePreviewTableAsync(id));
      }
    } else {
      dispatch(resetLiveForm());
    }
  }, [
    currentForm,
    isEdit,
    tablesIsLoading,
    fieldsIsLoading,
    formIsLoading,
    fields,
    tables,
    dispatch
  ]);
  console.log(currentForm, isEdit, tablesIsLoading, fieldsIsLoading, formIsLoading, fields, tables);
  const onCreateForm = async (form: Partial<Form>) => {
    try {
      const response = await createForm(form).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "newFormCreated",
            message: "",
            level: NotificationLevel.Success
          })
        );
        navigate(-1);
      }
    } catch (e) {
      const error = e as { data: ApiError };
      dispatch(
        setCurrentNotification({
          title: "formCreationError.",
          message: `\n ${error.data.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onUpdateForm = async (form: Partial<Form>) => {
    try {
      const response = await updateForm(form).unwrap();

      if (response) {
        dispatch(
          setCurrentNotification({
            title: "formUpdated",
            message: "",
            level: NotificationLevel.Success
          })
        );
        navigate(-1);
      }
    } catch (e) {
      const error = e as { data: ApiError };
      dispatch(
        setCurrentNotification({
          title: "formEditError",
          message: `\n ${error.data.detail}`,
          level: NotificationLevel.Error
        })
      );
    }
  };

  const onSaveForm = () => {
    const formToSave: Form | Partial<Form> = mapFormResult();

    isEdit ? onUpdateForm({ ...currentForm, ...formToSave }) : onCreateForm(formToSave);
  };

  const onCancel = useCallback(() => {
    dispatch(resetLiveForm());
    navigate(-1);
  }, [dispatch, navigate]);

  const isDisabled = useMemo(() => {
    const hasTabTitles = mapFormResult()?.containers?.every(item => item.displayName);

    return !formName || !formScope || currentForm?.category === Category.Default || !hasTabTitles;
  }, [currentForm?.category, formName, formScope, mapFormResult]);

  return (
    <Stack spacing={1}>
      {currentForm?.category === Category.Default && (
        <Alert severity="info">{t("viewingDefaultConfigMessage")}</Alert>
      )}
      <ConfigLayout
        t={(param: string) => {
          return param;
        }}
        saveDisabled={isDisabled}
        title={!isEdit ? t("newForm") : t("editForm")}
        onSaveCallback={onSaveForm}
        onCancelCallback={onCancel}
        topPanelContent={
          <>
            <TextField
              className="input"
              variant="standard"
              label={t("formName")}
              value={formName}
              onChange={e => {
                dispatch(setFormName(e.target.value));
                if (!isEdit)
                  dispatch(setInternalFormName(sanitiseInternalFieldName(e.target.value)));
              }}
            />
            <TextField
              className="input"
              label={t("internalName")}
              variant="standard"
              value={formInternalName ?? ""}
              disabled
            />
            <TextField
              className="input"
              variant="standard"
              label={t("description")}
              value={formDescription ?? ""}
              onChange={e => dispatch(setFormDescription(e.target.value))}
            />

            <FormControl className="input" variant="standard" required disabled={isEdit}>
              <InputLabel id="type-select">{t("scope")}</InputLabel>
              <Select
                labelId="type-select"
                variant="standard"
                value={formScope ?? RecordType.Projects}
                onChange={e => {
                  dispatch(setFormScope(e.target.value));
                }}
              >
                {Object.keys(RecordType)
                  .filter(type => isModuleEnabled(type as RecordType))
                  .map(module => (
                    <MenuItem key={module} value={module}>
                      {t(module)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </>
        }
      >
        <DndProvider backend={HTML5Backend}>
          <Grid className="form-designer-page-root" container spacing={3}>
            <Grid item xs={4} className="form-designer-page-root__item">
              <Paper>
                <Box>
                  <Features
                    disabledItemList={useAppSelector(selectFormsDisabledItemList)}
                    hiddenTabs={
                      [RecordType.Ideas, RecordType.Challenges].includes(formScope as RecordType)
                        ? ["tables"]
                        : undefined
                    }
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={8} className="form-designer-page-root__item">
              <LivePreview form={null}></LivePreview>
            </Grid>
          </Grid>
        </DndProvider>
      </ConfigLayout>
    </Stack>
  );
};
export default FormDesigner;
