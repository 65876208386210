import { Stack, styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getApprovalsAsync, selectPersonalApprovals } from "../../../store/slices/personalSlice";

import "./approvalstable.scss";
import "./approvalsrow/approvalsrow.scss";
import CollapsibleRow from "../collapsiblerow/CollapsibleRow";

import { LicenseType, WorkflowReviewState } from "enada-common";
import { EdisonTypography } from "enada-components";
import ApprovalsRow from "./approvalsrow/ApprovalsRow";
import HasMinimumLicense from "../../hasMinimumLicense/HasMinimumLicense";
const ApprovalsTable = () => {
  const { t } = useTranslation(["common"]);
  const approvals = useAppSelector(selectPersonalApprovals);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getApprovalsAsync());
  }, []);

  const isOverdue = useCallback((date?: string) => {
    if (!date) return false;
    return new Date(date).getTime() < new Date().getTime();
  }, []);
  const isComplete = useCallback((reviewState?: WorkflowReviewState) => {
    if (!reviewState) return false;
    if (reviewState === WorkflowReviewState.None) return false;
    return true;
  }, []);
  const overdueApprovals = approvals.filter(
    approval => !isComplete(approval.reviewState) && isOverdue(approval.dueDate)
  );

  const awaitingApprovals = approvals.filter(
    approval => !isComplete(approval.reviewState) && !isOverdue(approval.dueDate)
  );
  const completedApprovals = approvals.filter(approval => isComplete(approval.reviewState));

  return (
    <Stack className="approvals-table-root">
      <Table>
        <TableHead>
          <TableRow className="approval-table-row-root">
            <BorderedTableCell className="name">{t("name")}</BorderedTableCell>
            <BorderedTableCell className="type">{t("type")}</BorderedTableCell>
            <BorderedTableCell className="checklist">{t("checklist")}</BorderedTableCell>
            <BorderedTableCell className="stage">{t("stage")}</BorderedTableCell>
            <BorderedTableCell className="status">{t("status")}</BorderedTableCell>
            <HasMinimumLicense minimumUserLicenseType={LicenseType.Lite}>
              <BorderedTableCell className="action">{t("action")}</BorderedTableCell>
            </HasMinimumLicense>
          </TableRow>
        </TableHead>
        <TableBody>
          <CollapsibleRow
            columnsCount={7}
            title={
              <Stack direction="row" spacing={1}>
                <EdisonTypography
                  title={t("overdue")}
                  variant="data2"
                  sx={{ fontWeight: "bold" }}
                />
                <div>{`(${overdueApprovals.length} ${t("approvals")})`}</div>
              </Stack>
            }
          >
            {overdueApprovals.map((approval, index) => (
              <ApprovalsRow
                key={`${approval.itemId}-${index}`}
                approval={approval}
                isOverDue={true}
              />
            ))}
          </CollapsibleRow>

          <CollapsibleRow
            columnsCount={7}
            title={
              <Stack direction="row" spacing={1}>
                <EdisonTypography
                  title={t("awaitingApproval")}
                  variant="data2"
                  sx={{ fontWeight: "bold" }}
                />
                <div>{`(${awaitingApprovals.length} ${t("approvals")})`}</div>
              </Stack>
            }
          >
            {awaitingApprovals.map((approval, index) => (
              <ApprovalsRow
                key={`${approval.itemId}-${index}`}
                approval={approval}
                isOverDue={false}
              />
            ))}
          </CollapsibleRow>
          <CollapsibleRow
            columnsCount={7}
            title={
              <Stack direction="row" spacing={1}>
                <EdisonTypography
                  title={t("completed")}
                  variant="data2"
                  sx={{ fontWeight: "bold" }}
                />
                <div>{`(${completedApprovals.length} ${t("approvals")})`}</div>
              </Stack>
            }
          >
            {completedApprovals.map((approval, index) => (
              <ApprovalsRow
                key={`${approval.itemId}-${index}`}
                approval={approval}
                isOverDue={false}
              />
            ))}
          </CollapsibleRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

export const BorderedTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`
}));

export default ApprovalsTable;
