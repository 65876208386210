import {
  Resource,
  ResourceType,
  NotificationLevel,
  reducerStatus,
  Field,
  FieldValue,
  FieldValueOperationType,
  PersonaEntity,
  ApiError
} from "enada-common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  deleteResources,
  getIndividualResource,
  getIndividualResourceFieldValues,
  getMyResources,
  getResourceManagers,
  getResources,
  getTasks,
  postIndividualResourceFieldValues,
  postResource,
  putResource,
  recycleResources,
  restoreResources
} from "../../services/APIService";
import { RootState } from "../store";
import { setCurrentNotification } from "./notificationSlice";
import { formatFieldValueForBackend } from "../../utils/fieldHelpers";

export interface resourcesState {
  resources: Array<Resource>;
  resourceManagers: any;
  myResources: Array<Resource>;
  individualResource: Partial<Resource> | null;
  IndividualResourceValues: FieldValue[];
  IndividualResourceValuesChanges: FieldValue[];
  filteredResourceReport?: any;
  status: reducerStatus;
  resourceValueStatus: reducerStatus;
  error: ApiError | null;
}

const initialState: resourcesState = {
  resources: [],
  resourceManagers: [],
  myResources: [],
  individualResource: {
    type: ResourceType.AAD,
    isActive: true
  },
  IndividualResourceValues: [],
  IndividualResourceValuesChanges: [],
  filteredResourceReport: null,
  status: "idle",
  resourceValueStatus: "idle",
  error: null
};

export const getResourcesAsync: any = createAsyncThunk(
  "resources/getResources",
  async (_, { rejectWithValue }) => {
    const response: any = await getResources();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);

    return response.data;
  }
);
export const getResourceManagersAsync: any = createAsyncThunk(
  "resources/getResourceManagers",
  async (_, { rejectWithValue }) => {
    const response: any = await getResourceManagers();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);

    return response.data;
  }
);

export const getIndividualResourceAsync = createAsyncThunk(
  "resources/getIndividualResource",
  async (id: number, { rejectWithValue }) => {
    const response: any = await getIndividualResource(id);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);

    return response.data;
  }
);

export const getIndividualResourceValuesAsync = createAsyncThunk(
  "resources/getIndividualResourceValues",
  async (id: number, { rejectWithValue }) => {
    const response: any = await getIndividualResourceFieldValues(id);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);

    return response.data;
  }
);

export const getMyResourcesAsync: any = createAsyncThunk(
  "resources/getMyResources",
  async (_, { rejectWithValue }) => {
    const response: any = await getMyResources("");
    if (!response.value) {
      return rejectWithValue(response);
    }
    return response.value;
  }
);

export const deleteResourcesAsync = createAsyncThunk(
  "resources/deleteResources",
  async (ids: number[], { dispatch, rejectWithValue }) => {
    const response: any = await deleteResources(ids);
    if (!(response.status as number).toString().startsWith("2")) {
      return rejectWithValue(response);
    }

    dispatch(
      setCurrentNotification({
        title: "resourceDeleted",
        message: "",
        level: NotificationLevel.Success
      })
    );
    return ids;
  }
);

export const recycleResourcesAsync = createAsyncThunk(
  "resources/recycleResources",
  async (ids: number[], { dispatch, rejectWithValue }) => {
    const response: any = await recycleResources(ids);
    if (!(response.status as number).toString().startsWith("2")) {
      return rejectWithValue(response);
    }

    dispatch(
      setCurrentNotification({
        title: "resourceRecycled",
        message: response,
        level: NotificationLevel.Success
      })
    );
    return ids;
  }
);

export const restoreResourcesAsync = createAsyncThunk(
  "resources/restoreResources",
  async (ids: number[], { dispatch }) => {
    await restoreResources(ids);
    dispatch(
      setCurrentNotification({
        title: "resourceRestored",
        message: "",
        level: NotificationLevel.Success
      })
    );
    return ids;
  }
);

export const createResourceAsync = createAsyncThunk(
  "resources/createResource",
  async (data: Partial<Resource> | null, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await postResource(data);
      dispatch(
        setCurrentNotification({
          title: "newResourceCreated",
          message: "",
          level: NotificationLevel.Success
        })
      );

      if (!(response.status as number).toString().startsWith("2")) {
        dispatch(
          setCurrentNotification({
            title: "resourceCreationError",
            message: `\n ${response.detail}`,
            level: NotificationLevel.Error
          })
        );
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (e: any) {
      dispatch(
        setCurrentNotification({
          title: "resourceCreationError.",
          message: `\n ${e.response.detail}`,
          level: NotificationLevel.Error
        })
      );
      return rejectWithValue(e.response.data);
    }
  }
);

export const createResourceValuesAsync = createAsyncThunk(
  "resources/createResourceValues",
  async (
    data: { resourceId: number; fieldValues: FieldValue[] },
    { rejectWithValue, dispatch }
  ) => {
    const response: any = await postIndividualResourceFieldValues(data.resourceId, [
      {
        operationType: FieldValueOperationType.addOrUpdate,
        fieldValues: data.fieldValues
      }
    ]);
    if (!(response.status as number).toString().startsWith("2")) {
      dispatch(
        setCurrentNotification({
          title: "resourceFieldsUpdateError",
          message: `\n ${response.detail}`,
          level: NotificationLevel.Error
        })
      );
      return rejectWithValue(response.data);
    }
    return response.data;
  }
);

export const updateResourceAsync = createAsyncThunk(
  "resources/updateResources",
  async (data: Partial<Resource>, { rejectWithValue, dispatch }) => {
    const response: any = await putResource(data);
    if (!(response.status as number).toString().startsWith("2")) {
      dispatch(
        setCurrentNotification({
          title: "resourceEditError",
          message: `\n ${response.detail}`,
          level: NotificationLevel.Error
        })
      );
      return rejectWithValue(response.data);
    }
    dispatch(
      setCurrentNotification({
        title: "resourceUpdated",
        message: "",
        level: NotificationLevel.Success
      })
    );
    return response.data;
  }
);

export const getReportAsync: any = createAsyncThunk(
  "resources/getReport",
  async (data: { odataQuery: string; odataTaskQuery: string }, { rejectWithValue }) => {
    let resources = [];
    let tasks = [];

    if (data.odataQuery) {
      const responseResource = await getMyResources(data.odataQuery);
      if (!responseResource.value) {
        return rejectWithValue(responseResource);
      }
      resources = responseResource.value;
    }

    if (data.odataTaskQuery) {
      const responseTask = await getTasks(data.odataTaskQuery);
      if (!responseTask.value) {
        return rejectWithValue(responseTask);
      }
      tasks = responseTask.value;
    }

    return { resources, tasks };
  }
);

export const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearError: state => {
      state.error = null;
      state.status = "idle";
    },
    clearFilteredReport: state => {
      state.filteredResourceReport = null;
    },
    clearIndividualResource: state => {
      state.individualResource = {
        type: ResourceType.AAD,
        isActive: true
      };
    },
    updateIndividualResourceProperty: (
      state,
      action: PayloadAction<{ value: any; key: keyof Resource }>
    ) => {
      state.individualResource = {
        ...state.individualResource,
        [action.payload.key]: action.payload.value
      };
    },
    updateIndividualResourceType: (state, action: PayloadAction<ResourceType>) => {
      state.individualResource = {
        ...state.individualResource,
        type: action.payload,
        isActive: true,
        name: "",
        userId: null,
        department: "",
        email: ""
      };
    },
    updateResourceField: (state, action: PayloadAction<{ field: Field; value: any }>) => {
      const { field, value } = action.payload;
      // update field value collection mapped to compoenents on FE
      const existingFieldValueIndex = state.IndividualResourceValues.findIndex(
        rf => rf.fieldId === field.id
      );

      const newValue = {
        fieldId: field.id,
        ...formatFieldValueForBackend(field.dataType, value),
        resourceId: state.individualResource?.id
      };

      const updatedFieldValues = [...state.IndividualResourceValues];
      if (existingFieldValueIndex > -1) {
        updatedFieldValues[existingFieldValueIndex] = newValue;
      } else {
        updatedFieldValues.push(newValue);
      }

      state.IndividualResourceValues = updatedFieldValues;

      // update field value that will be saved to be
      const existingFieldValuesForBEIndex = state.IndividualResourceValuesChanges.findIndex(
        rf => rf.fieldId === field.id
      );

      const updatedFieldValuesForBE = [...state.IndividualResourceValuesChanges];
      if (existingFieldValuesForBEIndex > -1) {
        updatedFieldValuesForBE[existingFieldValuesForBEIndex] = newValue;
      } else {
        updatedFieldValuesForBE.push(newValue);
      }

      state.IndividualResourceValuesChanges = updatedFieldValuesForBE;
    },
    setRecordVersionResources: (state, action: PayloadAction<Resource[]>) => {
      state.myResources = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getMyResourcesAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(getMyResourcesAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getMyResourcesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.myResources = action.payload;
      })
      .addCase(getReportAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(getReportAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getReportAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) {
          state.filteredResourceReport = null;
          return;
        }
        const assignments: any[] = [];
        const owners: any[] = [];
        let index = 0;
        let startDate = "";
        let endDate = "";
        action.payload.tasks.forEach((t: any) => {
          if (startDate === "") {
            startDate = t.startDate;
          }
          if (new Date(startDate).getTime() > new Date(t.startDate).getTime()) {
            startDate = t.startDate;
          }
          if (endDate === "") {
            endDate = t.endDate;
          }
          if (new Date(endDate).getTime() < new Date(t.endDate).getTime()) {
            endDate = t.endDate;
          }
          t.recordTableRowAssignments.forEach((a: any) => {
            index += 1;
            const userId = state.resources.find(x => a.resource === x.id)?.userId;
            assignments.push({
              ...a,
              event: t.id,
              id: index,
              entityId: userId
            });
          });
          t.recordOwners.forEach((owner: PersonaEntity) => {
            owners.push({ ...owner, event: t.id });
          });
        });

        state.filteredResourceReport = {
          resources: action.payload.resources,
          assignments: assignments,
          recordOwners: owners,
          tasks: action.payload.tasks.map((t: any) => {
            return {
              ...t,
              manuallyScheduled: true
              //constraintType: "startnoearlierthan",
            };
          }),
          startDate, // Maybe use zoom to fit on scheduler pro instead?
          endDate
        };
      })
      .addCase(getResourcesAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getResourcesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.resources = action.payload;
      })
      .addCase(getResourceManagersAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getResourceManagersAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.resourceManagers = action.payload;
      })
      .addCase(getIndividualResourceAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getIndividualResourceAsync.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.individualResource = action.payload;

        state.status = "idle";
      })
      .addCase(getIndividualResourceValuesAsync.pending, state => {
        state.resourceValueStatus = "loading";
      })
      .addCase(getIndividualResourceValuesAsync.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.IndividualResourceValues = action.payload;
        state.resourceValueStatus = "idle";
      })
      .addCase(deleteResourcesAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(deleteResourcesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;

        state.resources = state.resources.filter(resource =>
          action.payload?.find((deletedId: number) => deletedId !== resource.id)
        );
      })
      .addCase(deleteResourcesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as ApiError;
      })
      .addCase(recycleResourcesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as ApiError;
      })
      .addCase(recycleResourcesAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(recycleResourcesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.resources = state.resources.map(resource =>
          action.payload.some((id: number) => id === resource.id)
            ? { ...resource, isDeleted: true }
            : resource
        );
      })
      .addCase(restoreResourcesAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(restoreResourcesAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(restoreResourcesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;

        state.resources = state.resources.map(resource =>
          action.payload.some((id: number) => id === resource.id)
            ? { ...resource, isDeleted: false }
            : resource
        );
      })
      .addCase(createResourceAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(createResourceAsync.rejected, state => {
        state.status = "failed";
      })
      .addCase(createResourceAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.resources.push(action.payload);
        if (state.individualResource) state.individualResource.id = 2;
      });
  }
});

export const {
  clearError,
  clearIndividualResource,
  clearFilteredReport,
  updateIndividualResourceProperty,
  updateIndividualResourceType,
  updateResourceField,
  setRecordVersionResources
} = resourcesSlice.actions;

export const selectError = (state: RootState) => state.resources.error;
export const selectResourcesRollUp = (state: RootState) => state.resources.resources;
export const selectResourceManagers = (state: RootState) => state.resources.resourceManagers;
export const selectIndividualResource = (state: RootState) => state.resources.individualResource;
export const selectIndividualResourceValues = (state: RootState) =>
  state.resources.IndividualResourceValues;
export const selectIndividualResourceValuesChanges = (state: RootState) =>
  state.resources.IndividualResourceValuesChanges;
export const selectMyResources = (state: RootState) => state.resources.myResources;
export const selectFilteredResourceReport = (state: RootState) =>
  state.resources.filteredResourceReport;
export const selectResourcesStatus = (state: RootState) => state.resources.status;
export const selectResourceValueStatus = (state: RootState) => state.resources.resourceValueStatus;

export default resourcesSlice.reducer;
