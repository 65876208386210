import {
  Column,
  ColumnField,
  ColumnTable,
  Field,
  Table,
  WorkflowDataPopulation,
  WorkflowStageViewField,
  WorkflowStageViewFieldState,
  WorkflowStageViewTable
} from "enada-common";
import { Stack } from "@mui/material";
import React, { Dispatch, FC, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectPopulationFieldValuesLoaded,
  selectPopulationFrontendFieldValues,
  selectPopulationObject,
  selectPopulationSliceStatus,
  updateDataPopulation,
  updatePopulationFrontendValues
} from "../../../../store/slices/dataPopulationSlice";
import { selectAllFields } from "../../../../store/slices/fieldsSlice";
import { selectFormsTableList, selectTableIds } from "../../../../store/slices/formDesignerSlice";
import { fieldTypesMap, RecordValueType } from "../../../../store/slices/recordSlice";
import ComponentMapper from "../../../../utils/componentMapper";
import PopulationTable from "../table/PopulationTable";
import { getComponentVisibility } from "../utils/handleFormVisibility";
import VisibilityToggle from "../visibilitytoggle/VisibilityToggle";
import { WorkflowLivePreviewSource } from "../WorkflowLivePreview";
import "./workflowlivepreviewcolumn.scss";
import { Loading } from "enada-components";
import { useGetTablesQuery } from "services/api";
import { useGetFieldsQuery } from "services/api";
import { selectInData } from "utils/rtkQuery";

export interface WorkflowLivePreviewColumnProps {
  column: Column;
  viewFields?: WorkflowStageViewField[];
  viewTables?: WorkflowStageViewTable[];
  setViewFields?: Dispatch<React.SetStateAction<WorkflowStageViewField[]>>;
  setViewTables?: Dispatch<React.SetStateAction<WorkflowStageViewTable[]>>;
  source: WorkflowLivePreviewSource;
  toggleDisabled?: boolean;
}
const WorkflowLivePreviewColumn: FC<WorkflowLivePreviewColumnProps> = ({
  column,
  viewFields,
  viewTables,
  setViewFields,
  setViewTables,
  source,
  toggleDisabled
}) => {
  const dispatch = useAppDispatch();

  // Commented out while we wait for the BE to optionally return the tables with the view[]
  // const tableIds = useAppSelector(selectTableIds);
  // const selectFormTableIds = useMemo(
  //   () =>
  //     selectInData<Table[]>(data => {
  //       return data?.filter(table => tableIds.includes(table.id ?? 0));
  //     }),
  //   [tableIds]
  // );

  const { tables = [] } = useGetTablesQuery(undefined, {
    selectFromResult: result => ({
      tables: result.data
      // formTables: selectFormTableIds(result)
    })
  });

  const formTables = useAppSelector(selectFormsTableList);

  const { fields = [] } = useGetFieldsQuery(undefined, {
    selectFromResult: result => ({
      ...result,
      fields: selectAllFields(result)
    })
  });

  const dataPopulation = useAppSelector(selectPopulationObject);
  const populationFieldValues = useAppSelector(selectPopulationFrontendFieldValues);
  const dataPopulationStatus = useAppSelector(selectPopulationSliceStatus);
  const fieldValuesLoaded = useAppSelector(selectPopulationFieldValuesLoaded);
  const onSingleVisibilityChange = (
    changeValue: WorkflowStageViewFieldState | undefined,
    componentData: any
  ) => {
    if (!setViewFields) return; // should  only occur with live preview for data prepopulation
    if (!setViewTables) return; // should  only occur with live preview for data prepopulation
    componentData.isTable
      ? setViewTables(prev =>
          prev.map(table =>
            table.tableId === componentData.component?.id ? { ...table, state: changeValue } : table
          )
        )
      : setViewFields(prev =>
          prev.map(field =>
            field.fieldId === componentData.component?.id ? { ...field, state: changeValue } : field
          )
        );
  };

  const isFieldSystem = (component: any) => {
    // If the components Id is undefined then the field is a system field
    return [undefined, null].includes(component.id) && component.configuration !== undefined;
  };
  const getSystemFieldValue = (columnField: any) => {
    if (columnField?.name === undefined || !dataPopulation) return null;
    return dataPopulation[columnField.name as unknown as keyof WorkflowDataPopulation];
  };
  const getComponentValue = ({ isTable, component, id }: ComponentData) => {
    if (!component) return;
    if (isTable) return;

    return isFieldSystem(component as Field)
      ? getSystemFieldValue(component as Field)
      : populationFieldValues.find(field => field.fieldId === component.id)?.value;
  };
  const onFieldChange = (changeValue: any, component: Field) => {
    const fieldType: RecordValueType | undefined = fieldTypesMap.find(
      type => type.fieldType === fields.find(field => field.id === component.id)?.dataType
    )?.recordValueType;
    isFieldSystem(component)
      ? dispatch(
          updateDataPopulation({
            key: component?.name as keyof WorkflowDataPopulation,
            changeValue: changeValue
          })
        )
      : dispatch(
          updatePopulationFrontendValues({
            id: component.id,
            changeValue: changeValue,
            type: fieldType
          })
        );
  };

  return (
    <Stack spacing={1} className="workflow-live-preview-column-root">
      {orderFieldsAndTables(column.fields ?? [], column.tables ?? [], fields, tables).map(
        (componentData, componentIndex) => (
          <Stack
            spacing={1}
            className="component-container"
            key={componentIndex}
            direction="row"
            sx={theme => ({
              backgroundColor: theme.palette.background.default
            })}
          >
            <div
              style={{
                width: source === "dataPrepopulation" ? "100%" : "90%",
                overflowX: "auto"
              }}
            >
              {(!componentData.isTable || dataPopulationStatus === "idle") && (
                <>
                  {componentData.isTable ? (
                    !!formTables && !formTables.some(a => a.id === componentData?.component?.id) ? (
                      <div>Loading</div>
                    ) : (
                      <PopulationTable
                        source={source}
                        fullTable={
                          formTables.find(
                            table => table.id === componentData?.component?.id
                          ) as Table
                        }
                      />
                    )
                  ) : !fieldValuesLoaded && source !== "form" ? (
                    <Loading size={30} />
                  ) : (
                    <ComponentMapper
                      component={componentData.component as Field}
                      readOnly={source === "form"}
                      isInTable={false}
                      useInternalState={source === "form"}
                      value={getComponentValue(componentData)}
                      onChange={(changeValue: any) => {
                        if (source === "form") return;
                        onFieldChange(changeValue, componentData.component as Field);
                      }}
                      useReadOnlyWrapper={true}
                    />
                  )}
                </>
              )}
            </div>
            {viewFields && viewTables && setViewFields && setViewTables && (
              <VisibilityToggle
                value={getComponentVisibility(
                  componentData?.component?.id,
                  componentData.isTable,
                  viewFields,
                  viewTables
                )}
                onChange={changeValue => {
                  onSingleVisibilityChange(changeValue, componentData);
                }}
                toggleDisabled={toggleDisabled}
              />
            )}
          </Stack>
        )
      )}
    </Stack>
  );
};

export default WorkflowLivePreviewColumn;

export interface ComponentData {
  id: string;
  isTable: boolean;
  component: Field | Table | undefined;
}
const orderFieldsAndTables = (
  columnFields: ColumnField[],
  columnTables: ColumnTable[],
  fieldList: Field[],
  tableList: Table[]
): ComponentData[] => {
  const concatenated = columnFields
    .map(field => ({ isTable: false, component: field }))
    .concat(columnTables.map(table => ({ isTable: true, component: table })));
  const ordered = concatenated.sort((a, b) => (a.component.order ?? 0) - (b.component.order ?? 0));
  return ordered.map((component, index) => ({
    id: index.toString(),
    isTable: component.isTable,
    component: component.isTable
      ? tableList.find(table => table.id === (component.component as ColumnTable).tableId)
      : fieldList.find(field => field.id === (component.component as ColumnField).fieldId) ??
        (component.component.configuration as unknown as Field) // Needed to map the system fields
  }));
};
