import { Paper } from "@mui/material";
import { nanoid } from "nanoid";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  getLivePreviewTableAsync,
  removeColumn,
  removeComponent,
  removeRow,
  selectCurrentTab,
  selectFormData,
  selectLayout,
  updateLayout
} from "../../store/slices/formDesignerSlice";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import DropZone from "./DropZone";
import "./livepreview.scss";
import Row from "./Row";
import TabContainer from "./TabContainer";
import {
  COLUMN,
  COMPONENT,
  handleMoveSidebarComponentIntoParent,
  handleMoveToDifferentParent,
  handleMoveWithinParent,
  isTableType,
  ItemDNDType,
  ROW,
  SIDEBAR_ITEM
} from "enada-common";

const ACCEPTS = [SIDEBAR_ITEM, COMPONENT, ROW, COLUMN];

export interface LivePreviewProps {
  form: any;
}

const LivePreview: FC<LivePreviewProps> = ({ form }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);

  const initialComponents = {};
  const [components, setComponents] = useState(initialComponents);

  const formData = useAppSelector(selectFormData);
  const currentTab = useAppSelector(selectCurrentTab);
  const layout = useAppSelector(selectLayout);

  const handleDrop = useCallback(
    async (dropZone: any, item: any) => {
      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
      const newItem: ItemDNDType = {
        id: item.id,
        title: null,
        type: item.type
      };
      if (item.type === COLUMN) {
        newItem.children = item.children;
      }

      // features into dropzone
      if (item.type === SIDEBAR_ITEM) {
        if (isTableType(item.component.dataType)) {
          dispatch(getLivePreviewTableAsync(item.id));
        }
        const newComponent = {
          fieldId: item.id,
          id: nanoid(),
          ...item.component
        };
        const newItem: ItemDNDType = {
          id: newComponent.id,
          title: null,
          type: COMPONENT,
          component: { ...item.component }
        };
        setComponents({
          ...components,
          [newComponent.id]: newComponent
        });
        dispatch(
          updateLayout(handleMoveSidebarComponentIntoParent(layout, splitDropZonePath, newItem))
        );
        return;
      }

      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      // move without create
      if (splitItemPath.length === splitDropZonePath.length) {
        // move within parent
        if (pathToItem === pathToDropZone) {
          dispatch(updateLayout(handleMoveWithinParent(layout, splitDropZonePath, splitItemPath)));
          return;
        }
        // move to another parent
        dispatch(
          updateLayout(
            handleMoveToDifferentParent(layout, splitDropZonePath, splitItemPath, {
              ...newItem,
              ...item.children
            })
          )
        );
        return;
      }
      // move and create
      dispatch(
        updateLayout(
          handleMoveToDifferentParent(layout, splitDropZonePath, splitItemPath, {
            ...newItem,
            ...item.children
          })
        )
      );
    },
    [layout, components]
  );
  const renderRow = (row: any, currentPath: string) => {
    return (
      <Row
        key={row.id}
        data={row}
        handleDrop={handleDrop}
        path={currentPath}
        removeRow={(path: string) => dispatch(removeRow(path))}
        removeColumn={(path: string) => dispatch(removeColumn(path))}
        removeComponent={(path: string, id: string) => dispatch(removeComponent({ path, id }))}
      />
    );
  };

  return (
    <div className="livepreview">
      <div className="livepreview_body">
        <Paper>
          <TabContainer data={formData} />
        </Paper>
        <div className="pageContainer">
          <div className="page">
            {/* <div className="lp_page-title">
              <EdisonTypography title={currentTab.title || ""} variant="data" />
              <EdisonTypography
                title={`(${t("dndToReorder")})`}
                variant="data"
              />
            </div> */}
            {layout.map((row, index) => {
              const currentPath = `${index}`;
              return (
                <React.Fragment key={row.id}>
                  <DropZone
                    data={{
                      path: currentPath,
                      childrenCount: layout.length
                    }}
                    onDrop={handleDrop}
                  />
                  {renderRow(row, currentPath)}
                </React.Fragment>
              );
            })}
            <DropZone
              data={{
                path: `${layout.length}`,
                childrenCount: layout.length
              }}
              onDrop={handleDrop}
              isLast
            />
          </div>
          {/* ADD NEW ROW BUTTON - Might be required in the future */}
          {/* <div className="livepreview_add-row">
            <Button
              onClick={() => dispatch(addNewRow())}
              color="inherit"
              startIcon={<AddCircleOutlineIcon />}>
              <EdisonTypography title={t("addNewRow")} variant="data" />
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LivePreview;
