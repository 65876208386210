import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import buildQuery from "odata-query";
import { EdisonCarousel, EdisonTypography } from "enada-components";
import { BaseRecord } from "enada-common";
import { useTranslation } from "react-i18next";
import RecordContentCard from "../../../recordcontentcard/RecordContentCard";

import "./myfavourites.scss";
import { getFavourites } from "../../../../services/APIService";
import { useEffectOnce } from "react-use";
import { useGetWorkflowStagesQuery } from "services/api";

const defaultOdataQuery = {
  expand: "owners",
  orderBy: "created desc",
  skip: 0
};

const MyFavourites = () => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  const { data: stages = [] } = useGetWorkflowStagesQuery();

  const [items, setItems] = useState<BaseRecord[]>([]);
  const [hasMoreFavourites, setHasMoreFavourites] = useState(true);
  const [odataQuery, setOdataQuery] = useState(defaultOdataQuery);

  const [expandedProject, setExpandedProject] = useState<number | undefined>();

  const fetchFavourites = async () => {
    const response = await getFavourites(buildQuery(odataQuery));
    if (response.value) {
      setItems(response.value);

      if (response.value.length < 20) {
        setHasMoreFavourites(false);
      }
    }
  };

  useEffectOnce(() => {
    fetchFavourites();
  });

  useEffect(() => {
    if (items.length % 20 !== 0) {
      setHasMoreFavourites(false);
    }
  }, [items]);

  const fetchMoreFavourites = async () => {
    const updatedQuery = {
      ...odataQuery,
      skip: odataQuery.skip + 20
    };

    const response = await getFavourites(buildQuery(updatedQuery));
    if (response.value) {
      setItems([...items, ...response.value]);

      setOdataQuery(updatedQuery);

      if (response.value.length < 20) {
        setHasMoreFavourites(false);
      }
    }
  };

  const recordCard = (record: BaseRecord) => {
    return (
      <RecordContentCard
        stages={stages}
        record={record}
        isExpanded={expandedProject ? expandedProject === record.id : false}
        setExpandedRecord={setExpandedProject}
        onRecordClick={project =>
          navigate(`../${project.recordType.toLocaleLowerCase()}/${project.id}`, {
            state: { record: project }
          })
        }
      />
    );
  };

  return (
    <Stack className="events-container">
      <Stack className="title-container" direction="row" padding="10px">
        <EdisonTypography title={t("myfavourites")} variant="h1" />
      </Stack>
      {!items.length ? (
        <EdisonTypography
          title={t("noResultsMessage")}
          variant="data2"
          sx={{ marginTop: 5, marginBottom: 2 }}
        />
      ) : (
        <EdisonCarousel
          data={items}
          getCard={recordCard}
          noDataModule={<div>{t("noData")}</div>}
          isMoreActive={hasMoreFavourites}
          clickMore={fetchMoreFavourites}
          moreText={t("loadMore")}
        />
      )}
    </Stack>
  );
};

export default MyFavourites;
