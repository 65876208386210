import {
  CalculatedExpressionUnit,
  ConditionBlock,
  ConditionalExpression,
  Expression,
  ExpressionDataType
} from "enada-common";
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { postSimulateExpression } from "../../services/APIService";

import { RootState } from "../store";

export interface EvaluateExpressionQuery {
  expression?: Expression;
  conditionalExpression?: ConditionalExpression;
}
export interface CalculatedFieldState {
  simpleExpression: CalculatedExpressionUnit[];
  conditionBlock?: ConditionBlock;
  resultType: ExpressionDataType;
  barLocation: BarLocation;
  selectedUnit: UnitLocation | null;
  isCondition: boolean;
}

//TODO: Once feature stable move models to more appropriate place

// Reference : https://stackoverflow.com/questions/51419176/how-to-get-a-subset-of-keyof-t-whose-value-tk-are-callable-functions-in-typ
type KeyOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never];
};

type TargetType = KeyOfType<ConditionBlock, CalculatedExpressionUnit[] | undefined>;
export interface ExpressionLocation {
  path: string | null;
  target: TargetType;
}

export interface UnitLocation extends Partial<ExpressionLocation> {
  index: number;
}
export interface BarLocation extends Partial<ExpressionLocation> {
  index: number | "end";
}

const initialState: CalculatedFieldState = {
  simpleExpression: [],
  conditionBlock: { if: [], then: [], else: [] },
  resultType: ExpressionDataType.Decimal,
  barLocation: { index: "end" },
  selectedUnit: null,
  isCondition: false
};

export const postSimulateExpressionAsync = createAsyncThunk(
  "calculated/simulateExpression",
  async (body: Expression, { rejectWithValue }) => {
    const response = await postSimulateExpression({ expression: body });
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);

    return response.data;
  }
);

const calculatedFieldSlice = createSlice({
  name: "calculated",
  initialState,
  reducers: {
    setSimpleExpression: (state, action: PayloadAction<CalculatedExpressionUnit[]>) => {
      state.simpleExpression = action.payload;
    },
    setConditionExpression: (state, action: PayloadAction<ConditionBlock>) => {
      state.conditionBlock = action.payload;
    },
    insertUnitIntoSimpleExpression: (state, action: PayloadAction<CalculatedExpressionUnit>) => {
      state.selectedUnit = null;
      state.simpleExpression = insertUnitIntoExpression(state.simpleExpression, {
        unit: action.payload,
        index: state.barLocation.index
      });
      if (state.barLocation.index !== "end") {
        state.barLocation.index += 1;
      }
    },
    moveUnitInSimpleExpression: (
      state,
      action: PayloadAction<{
        sourceIndex: number;
        targetIndex: number | "end";
      }>
    ) => {
      const source = action.payload.sourceIndex;
      const target = action.payload.targetIndex;
      const deleted = state.simpleExpression.splice(source, 1);
      target === "end"
        ? state.simpleExpression.push(deleted[0])
        : state.simpleExpression.splice(source < target ? target - 1 : target, 0, deleted[0]);
    },
    removeUnitFromSimpleExpression: (state, action: PayloadAction<number>) => {
      state.simpleExpression = state.simpleExpression.filter(
        (_parts, index) => index !== action.payload
      );
    },
    insertUnitIntoConditionBlock: (
      state,
      action: PayloadAction<{
        unit: CalculatedExpressionUnit;
        location?: BarLocation;
      }>
    ) => {
      const { target, index, path } = (action.payload.location ??
        state.barLocation) as Required<UnitLocation>;
      if (state.conditionBlock) {
        state.conditionBlock = updateEntityInBlock(
          state.conditionBlock,
          path ?? "",
          target,
          "insert",
          { index, value: action.payload.unit }
        );
      }
      state.selectedUnit = null;
      if (!action.payload.location && state.barLocation.index !== "end") {
        state.barLocation.index += 1;
      }
    },
    removeUnitFromConditionBlock: (
      state,
      action: PayloadAction<{
        path: ExpressionLocation;
        index: number;
      }>
    ) => {
      if (!state.conditionBlock) return;
      const { path: pathToExpression, index } = action.payload;
      state.conditionBlock = updateEntityInBlock(
        state.conditionBlock,
        pathToExpression.path,
        pathToExpression.target,
        "remove",
        index
      );
    },
    moveUnitInConditionBlockExpression: (
      state,
      action: PayloadAction<{
        target: TargetType;
        path: string;
        sourceIndex: number;
        targetIndex: number | "end";
      }>
    ) => {
      if (!state.conditionBlock) return;
      const { target, sourceIndex, targetIndex, path } = action.payload;
      state.conditionBlock = updateEntityInBlock(state.conditionBlock, path, target, "move", {
        sourceIndex,
        targetIndex
      });
    },
    setCalculatedResultType: (state, action: PayloadAction<ExpressionDataType>) => {
      state.resultType = action.payload;
    },
    clearConditionBlock: (
      state,
      action: PayloadAction<{ target: TargetType; path: string | null } | null>
    ) => {
      if (!state.conditionBlock) return;
      if (!action.payload) {
        state.conditionBlock = { if: [], then: [], else: [] };
        return;
      }
      const { target, path } = action.payload;
      if (!path && target === "if") return;

      state.conditionBlock = updateEntityInBlock(state.conditionBlock, path, target, "clear");
    },
    resetCalculatedSlice: (
      state,
      action: PayloadAction<CalculatedExpressionUnit[] | undefined>
    ) => {
      state.conditionBlock = { if: [], then: [], else: [] };
      state.simpleExpression = action.payload ?? [];
      state.isCondition = false;
      state.resultType = ExpressionDataType.Decimal;
      state.barLocation = { index: "end" };
      state.selectedUnit = null;
    },
    setBarLocation: (state, action: PayloadAction<BarLocation>) => {
      state.barLocation = action.payload;
    },
    setSelectedUnit: (state, action: PayloadAction<UnitLocation | null>) => {
      if (action.payload === null) {
        state.selectedUnit = null;
        return;
      }
      state.selectedUnit = action.payload;
    },
    updateUnitFixedValue: (state, action: PayloadAction<number | string>) => {
      if (!state.selectedUnit) return; // There must be a selected unit to be able to change the value of the unit
      const { target, index, path } = state.selectedUnit as Required<UnitLocation>;
      if (state.isCondition) {
        if (!state.conditionBlock) return;
        state.conditionBlock = updateEntityInBlock(
          state.conditionBlock,
          path,
          target,
          "updateFixedValue",
          { index: index, value: action.payload }
        );
      } else {
        state.simpleExpression[index].value = action.payload;
      }
    },
    setIsCondition: (state, action: PayloadAction<boolean>) => {
      state.isCondition = action.payload;
      state.barLocation = action.payload
        ? { index: "end", path: "", target: "if" }
        : { index: "end" };
    },
    insertIf: state => {
      if (!state.isCondition) return;
      if (!state.conditionBlock) return;
      if (state.barLocation.target === "if") return;
      const target: keyof ConditionBlock =
        state.barLocation.target === "then" ? "conditionalThen" : "conditionalElse";
      state.conditionBlock = updateEntityInBlock(
        state.conditionBlock,
        state.barLocation.path ?? "",
        target,
        "insert"
      );
      const currentBarPath = state.barLocation.path;
      state.barLocation = {
        index: "end",
        path: currentBarPath ? `${currentBarPath}-${target}` : target,
        target: "if"
      };
    }
  }
});
export const {
  insertUnitIntoSimpleExpression,
  moveUnitInSimpleExpression,
  removeUnitFromSimpleExpression,
  setSimpleExpression,
  removeUnitFromConditionBlock,
  moveUnitInConditionBlockExpression,
  setConditionExpression,
  setCalculatedResultType,
  clearConditionBlock,
  resetCalculatedSlice,
  setBarLocation,
  setSelectedUnit,
  updateUnitFixedValue,
  setIsCondition,
  insertUnitIntoConditionBlock,
  insertIf
} = calculatedFieldSlice.actions;

const inputSelectCalculated = (state: RootState) => state.calculated;

export const selectCalculatedSimpleExpression = createSelector(
  [inputSelectCalculated],
  calculated => calculated.simpleExpression
);

export const selectCalculatedConditionBlock = createSelector(
  [inputSelectCalculated],
  calculated => calculated.conditionBlock
);

export const selectCalculatedResultType = createSelector(
  [inputSelectCalculated],
  calculated => calculated.resultType
);

export const selectBarLocation = createSelector(
  [inputSelectCalculated],
  calculated => calculated.barLocation
);

export const selectCalculatedSelectedUnit = createSelector(
  [inputSelectCalculated],
  calculated => calculated.selectedUnit
);

export const selectIsCondition = createSelector(
  [inputSelectCalculated],
  calculated => calculated.isCondition
);

// Helper methods
const insertUnitIntoExpression = (
  list: CalculatedExpressionUnit[],
  moveAction: {
    index: number | "start" | "end";
    unit: CalculatedExpressionUnit;
  }
): CalculatedExpressionUnit[] => {
  const copy = [...list];
  switch (moveAction.index) {
    case "start":
      copy.unshift(moveAction.unit);
      break;
    case "end":
      copy.push(moveAction.unit);
      break;
    default:
      copy.splice(moveAction.index, 0, moveAction.unit);
      break;
  }
  return copy;
};

const moveUnitInExpressionList = (
  list: CalculatedExpressionUnit[],
  sourceIndex: number,
  targetIndex: number | "end"
) => {
  const deleted = list.splice(sourceIndex, 1);
  targetIndex === "end"
    ? list.push(deleted[0])
    : list.splice(sourceIndex < targetIndex ? targetIndex - 1 : targetIndex, 0, deleted[0]);
  return list;
};
interface InserUnitAction {
  value: CalculatedExpressionUnit;
  index: number;
}
interface MoveUnitAction {
  sourceIndex: number;
  targetIndex: number | "end";
}
interface UpdateUnitFixedValueAction {
  index: number;
  value: string | number;
}
const updateEntityInBlock = (
  block: ConditionBlock,
  path: string | null,
  target: keyof ConditionBlock,
  updateType: "remove" | "insert" | "move" | "updateFixedValue" | "clear",
  updateValue?: number | InserUnitAction | MoveUnitAction | UpdateUnitFixedValueAction
): ConditionBlock | undefined => {
  if (!path) {
    // If path is empty or null then we have hit the root level of recursion, return the updated block
    switch (updateType) {
      case "insert":
        return insertIntoBlock(block, target, updateValue as InserUnitAction);
      case "remove":
        return removeFromBlock(block, target, updateValue as number);
      case "updateFixedValue": {
        const list = block[target] as CalculatedExpressionUnit[];
        const { index, value } = updateValue as UpdateUnitFixedValueAction;
        return {
          ...block,
          [target]: list.map((unitInList, mapIndex) =>
            index === mapIndex ? { ...unitInList, value: value } : unitInList
          )
        };
      }
      case "clear":
        if (target === "if") return undefined;
        return {
          ...block,
          [target]: []
        };
      default: {
        // case - move
        const { sourceIndex, targetIndex } = updateValue as MoveUnitAction;
        const listToUpdate = block[target] as CalculatedExpressionUnit[];
        return {
          ...block,
          [target]: moveUnitInExpressionList(listToUpdate, sourceIndex, targetIndex)
        };
      }
    }
  }
  const split = path.split(`-`);
  const key = split.shift() as keyof ConditionBlock;
  const newPath =
    split.length === 0
      ? null
      : split.reduce((prev, cur) => {
          return `${prev}-${cur}`;
        });

  const recursiveUpdate = updateEntityInBlock(
    block[key] as ConditionBlock,
    newPath,
    target,
    updateType,
    updateValue
  );
  const keyToAdd: keyof ConditionBlock = key === "conditionalThen" ? "then" : "else";
  return recursiveUpdate
    ? { ...block, [key]: recursiveUpdate }
    : { ...block, [key]: recursiveUpdate, [keyToAdd]: [] };
};

const insertIntoBlock = (
  block: ConditionBlock,
  target: keyof ConditionBlock,
  unitToInsert?: InserUnitAction
): ConditionBlock => {
  if (unitToInsert !== undefined) {
    //If unitToInsert has a value then we are inserting a unit into an if, a then or an else
    return {
      ...block,
      [target]: insertUnitIntoExpression(block[target as TargetType] ?? [], {
        unit: unitToInsert.value,
        index: unitToInsert.index
      })
    };
  } else {
    //if the unitToInsert is undefined then we are inserting a new ConditionBlock
    // target should either be conditionalThen or conditionalElse
    const toRemove: keyof ConditionBlock = target === "conditionalThen" ? "then" : "else";
    return {
      ...block,
      [target]: { if: [], then: [], else: [] },
      [toRemove]: null
    };
  }
};
const removeFromBlock = (
  block: ConditionBlock,
  target: keyof ConditionBlock,
  indexToRemove?: number
): ConditionBlock => {
  if (indexToRemove === undefined) {
    //If index to remove does not have a value then we are deleting a nested block
    const toReAdd: keyof ConditionBlock = target === "conditionalThen" ? "then" : "else";
    return { ...block, [target]: null, [toReAdd]: [] };
  } else {
    //if the index to remove has a value then we are deleting a unit from a list of units
    // deleting from then, if or else
    const targetList = block[target] as CalculatedExpressionUnit[];
    return {
      ...block,
      [target]: targetList.filter((_, index) => indexToRemove !== index)
    };
  }
};

export default calculatedFieldSlice.reducer;
