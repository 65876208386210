import { Alert, Button, Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  updateRecordRoles,
  selectWorkflowStage,
  setIsFlyoutOpen,
  selectRecordAuth,
  postRecordPermissionsAsync,
  selectRecordPermissions,
  RecordSettings,
  selectRecordType,
  selectRecord,
  selectRecordAssociations,
  getHeadersFromAuth
} from "../../store/slices/recordSlice";
import { EdisonPeoplePickerField } from "enada-components";
import {
  PermissionType,
  PersonaEntity,
  RecordPermission,
  RecordRole,
  RecordType
} from "enada-common";
import ProjectRecordFlyout from "../projects/projectrecordflyout/ProjectRecordFlyout";
import { RecordFlag } from "../recordFlag/RecordFlag";
import { useGetTemplateQuery } from "../../services/api";
import { useGetDynamicPersons } from "../../utils/hooks/useGetDynamicPersons";
import { getAccessToken, getIndividualRecord } from "../../services/APIService";

const ManageAccess: FC<{ readOnly: boolean }> = ({ readOnly }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const record = useAppSelector(selectRecord);
  const recordAuth = useAppSelector(selectRecordAuth);
  const recordType = useAppSelector(selectRecordType);
  const recordPermissions = useAppSelector(selectRecordPermissions);
  const { data: recordTemplate } = useGetTemplateQuery(record?.recordTemplateId ?? 0, {
    skip: !record?.recordTemplateId
  });
  const associations = useAppSelector(selectRecordAssociations);
  const [parentPermissions, setParentPermissions] = useState<{
    owners: RecordPermission[];
    managers: RecordPermission[];
  }>({ owners: [], managers: [] });

  useEffect(() => {
    const fetchData = async () => {
      if (recordType === RecordType.Ideas && associations.length > 0) {
        const additionalHeaders = new Headers();

        additionalHeaders.append("edison365-sessionid", "frontend");
        const tokenResult = (
          await getAccessToken(
            associations[0].recordId as number,
            associations[0].recordType as RecordType,
            additionalHeaders
          )
        ).data;

        const projectAuth = {
          permissionToken: tokenResult.permissionToken,
          sessionId: "frontend",
          details: tokenResult.details
        };

        const response = await getIndividualRecord(
          associations[0].recordId,
          RecordType.Challenges,
          getHeadersFromAuth(projectAuth)
        );
        // We get the permissions from the parent record and we set the owners as managers and the ideators as owners to pass them to the useGetDynamicPersons hook.
        if (response?.data?.recordPermissions) {
          const ideatorsParent = [...response.data.recordPermissions]
            .filter(e => e.role === RecordRole.Ideator)
            .map(e => {
              return { ...e, role: RecordRole.Owner };
            });
          const ownersParent = [...response.data.recordPermissions]
            .filter(e => e.role === RecordRole.Owner)
            .map(e => {
              return { ...e, role: RecordRole.Manager };
            });
          setParentPermissions({ owners: ideatorsParent, managers: ownersParent });
        }
      }
    };
    //If the record is an idea we need to get the parent record to get the managers and the owners.
    fetchData();
  }, [associations, recordType]);

  const { filteredPersons: filteredOwners, selectedPeople: selectedOwners } = useGetDynamicPersons(
    RecordRole.Owner,
    recordTemplate,
    recordPermissions,
    recordType,
    undefined,
    undefined,
    recordType === RecordType.Ideas ? parentPermissions.owners : undefined
  );

  const { filteredPersons: filteredManagers, selectedPeople: selectedManagers } =
    useGetDynamicPersons(
      RecordRole.Manager,
      recordTemplate,
      recordPermissions,
      recordType,
      undefined,
      undefined,
      recordType === RecordType.Ideas ? parentPermissions.managers : undefined
    );

  const owners = recordPermissions
    .filter(permission => permission.role === RecordRole.Owner)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const managers = recordPermissions
    .filter(permission => permission.role === RecordRole.Manager)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const readers = recordPermissions
    .filter(permission => permission.role === RecordRole.Reader)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const editors = recordPermissions
    .filter(permission => permission.role === RecordRole.Editor)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const reviewers = recordPermissions
    .filter(permission => permission.role === RecordRole.Reviewer)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const ideators = recordPermissions
    .filter(permission => permission.role === RecordRole.Ideator)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const readOnlyAccess = recordPermissions
    .filter(permission => permission.role === RecordRole.ReadOnly)
    .map(role => permissionToPersona(role.entityId, role.permissionType));

  const currentWorkflowStage = useAppSelector(selectWorkflowStage);

  return (
    <ProjectRecordFlyout title={t(RecordSettings.manageAccess)}>
      <>
        <Stack spacing={2}>
          {readOnly && <Alert severity="info">{t("manageAccessReadOnlyMessage")}</Alert>}
          <EdisonPeoplePickerField
            multiple
            disabled={readOnly}
            label={t("processManagers")}
            selectedPeople={selectedManagers}
            filteredPeople={filteredManagers ?? []}
            onChange={(entities: PersonaEntity[]) => {
              dispatch(updateRecordRoles({ role: RecordRole.Manager, entities }));
            }}
            useInternalState={false}
          />
          <EdisonPeoplePickerField
            multiple
            disabled={readOnly}
            label={t("owners")}
            selectedPeople={selectedOwners}
            filteredPeople={filteredOwners ?? []}
            onChange={(entities: PersonaEntity[]) => {
              dispatch(updateRecordRoles({ role: RecordRole.Owner, entities }));
            }}
            useInternalState={false}
          />
          <RecordFlag recordType={recordType} hiddenRecordTypes={[RecordType.Challenges]}>
            <EdisonPeoplePickerField
              multiple
              disabled={readOnly}
              label={t("editors")}
              value={editors}
              onChange={(entities: PersonaEntity[]) => {
                dispatch(updateRecordRoles({ role: RecordRole.Editor, entities }));
              }}
              useInternalState={false}
            />
          </RecordFlag>
          <EdisonPeoplePickerField
            multiple
            disabled={readOnly}
            label={t("readers")}
            value={readers}
            onChange={(entities: PersonaEntity[]) => {
              dispatch(updateRecordRoles({ role: RecordRole.Reader, entities }));
            }}
            useInternalState={false}
          />

          <RecordFlag recordType={recordType} hideComponent={recordType !== RecordType.Challenges}>
            <EdisonPeoplePickerField
              multiple
              disabled={readOnly}
              label={t("ideators")}
              onChange={(entities: PersonaEntity[]) => {
                dispatch(updateRecordRoles({ role: RecordRole.Ideator, entities }));
              }}
              useInternalState={false}
              value={ideators}
            />
          </RecordFlag>

          {currentWorkflowStage?.hasReview && (
            <>
              {currentWorkflowStage?.requiredApproversCount !== reviewers?.length && (
                <Alert severity="warning">{t("manageAccessMinimumReviewersWarning")}</Alert>
              )}
              <EdisonPeoplePickerField
                multiple
                disabled
                label={t("reviewers")}
                value={reviewers}
                onChange={(entities: PersonaEntity[]) => {
                  dispatch(updateRecordRoles({ role: RecordRole.Reviewer, entities }));
                }}
                useInternalState={false}
              />
            </>
          )}

          {readOnlyAccess?.length > 0 && (
            <RecordFlag recordType={recordType} hideComponent={recordType === RecordType.Programs}>
              <EdisonPeoplePickerField
                multiple
                disabled
                label={t("readOnlyAccess")}
                useInternalState={false}
                value={readOnlyAccess}
              />
            </RecordFlag>
          )}
        </Stack>
        {!readOnly && (
          <Stack
            direction="row"
            sx={{ marginTop: "100px" }}
            spacing={1}
            justifyContent={"flex-end"}
          >
            <Button
              variant="contained"
              disabled={owners.length === 0 && managers.length === 0}
              onClick={() => {
                if (!recordAuth) return;
                dispatch(
                  postRecordPermissionsAsync({
                    recordAuth,
                    recordPermissions,
                    recordType
                  })
                );
                dispatch(setIsFlyoutOpen(false));
              }}
            >
              {t("save&close")}
            </Button>
          </Stack>
        )}
      </>
    </ProjectRecordFlyout>
  );
};

export const permissionToPersona = (entityId: string, permissionType: PermissionType) =>
  ({
    entityId,
    type: permissionType,
    userName: "",
    presence: ""
  } as PersonaEntity);
export default ManageAccess;
