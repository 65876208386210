import { Box } from "@mui/material";
import React, { FC, useRef } from "react";
import { useDrag } from "react-dnd";
import {
  selectFormDesignerIsReadOnly,
  selectFormsTableList
} from "../../store/slices/formDesignerSlice";
import { useAppSelector } from "../../store/hooks";
import "./column.scss";
import DropZone from "./DropZone";
import UserComponent from "./UserComponent";
import { COLUMN } from "enada-common";

export interface ColumnProps {
  data: any;
  handleDrop: any;
  path: string;
  removeColumn: any;
  removeComponent: any;
}

const Column: FC<ColumnProps> = ({ data, handleDrop, path, removeColumn, removeComponent }) => {
  const ref = useRef(null);
  const readOnly = useAppSelector(selectFormDesignerIsReadOnly);

  const tableList = useAppSelector(selectFormsTableList);

  // Commented out while we wait for the BE to optionally return the tables with the view[]
  // const tableIds = useAppSelector(selectTableIds);

  // const selectFormTableIds = useMemo(
  //   () =>
  //     selectInData<Table[]>(data => {
  //       return data?.filter(table => tableIds.includes(table.id ?? 0));
  //     }),
  //   [tableIds]
  // );

  // const { tableList = [] } = useGetTablesQuery(undefined, {
  //   selectFromResult: result => ({
  //     tableList: selectFormTableIds(result)
  //   })
  // });

  const [{ isDragging }, drag] = useDrag({
    type: COLUMN,
    item: {
      type: COLUMN,
      id: data.id,
      children: data.children,
      path
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderComponent = (component: any, currentPath: string) => {
    return (
      <UserComponent
        key={component.id}
        data={component}
        path={currentPath}
        removeComponent={removeComponent}
        readOnly={readOnly}
        tableList={tableList}
        source="formDesigner"
      />
    );
  };
  return (
    <Box className={`base draggable column`} ref={ref} style={{ width: "100%", opacity }}>
      <div className={"column-content"}>
        {
          <>
            {data.children.map((component: any, index: number) => {
              const currentPath = `${path}-${index}`;
              return (
                <React.Fragment key={`${component.id}${component.component.dataType}`}>
                  <DropZone
                    data={{
                      path: currentPath,
                      childrenCount: data.children.length
                    }}
                    onDrop={handleDrop}
                  />
                  {renderComponent(component, currentPath)}
                </React.Fragment>
              );
            })}
            <DropZone
              data={{
                path: `${path}-${data.children.length}`,
                childrenCount: data.children.length
              }}
              onDrop={handleDrop}
              isLast
            />
          </>
        }
      </div>
    </Box>
  );
};
export default Column;
